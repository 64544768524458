import { Box, Text, Image, Button, Input } from '@chakra-ui/react';
import { useState } from 'react';
import { ethers } from 'ethers';

import useUser from '../../../../hooks/useUser';
import useClient from '../../../../hooks/useClient';

const AddressForm = ({ setSuccess, setVideoRecorderStep }) => {
    const [isInvalidAddress, setIsInvalidAddress] = useState(false);
    const [localAddress, setLocalAddress] = useState('');

    const { address, setAddress, setHasAddress } = useUser();
    const { updateToken, updateAddress } = useClient();

    async function AddAddress() {
        try {
            const isAddress = ethers.utils.isAddress(localAddress);

            if (!localAddress || !isAddress)
                setIsInvalidAddress(true);
            else {
                await updateToken();
                await updateAddress([{
                    op: "add",
                    path: "/paymentEthAddress",
                    value: localAddress
                }]);

                let profile = JSON.parse(localStorage.getItem('data'));
                profile.address = localAddress;
                localStorage.setItem('data', JSON.stringify(profile));

                setAddress(localAddress);
                localStorage.setItem('hasAddress', JSON.stringify(true));

                setHasAddress(true);
                setVideoRecorderStep();
            }
        }
        catch (error) {
            console.error(error);
            console.log('Something wrong happend');
        }
    }

    return (
        <Box>
            <Image
                src='/logo.png'
                alt='logo-image'
                w={{ base: '60px', md: "80px" }}
                margin="30px auto"
            />
            <Text
                fontWeight="600"
                fontSize={{ base: "25px", md: "40px" }}
                lineHeight={{ base: "31px", md: "48px" }}
                textAlign="center"
            >
                What is your Ethereum address?
            </Text>
            <Text
                fontWeight="400"
                fontSize={{ base: "17px", md: "26px" }}
                textAlign="center"
                color="#C2C2C2"
                marginBottom="40px"
            >
                Metamask recommended
            </Text>
            {isInvalidAddress &&
                <Text
                    fontWeight="400"
                    fontSize="18px"
                    color="#FF6760"
                    lineHeight="22px"
                >
                    Wrong address, try again
                </Text>
            }
            <Input
                placeholder='Type your address here'
                width="100%"
                height={{ base: "48px", md: "73px" }}
                margin="10px 0"
                borderRadius={{ base: '7px', md: "10px" }}
                border={{ base: '1px solid #E7E9ED', md: "2px solid #E7E9ED" }}
                isInvalid={isInvalidAddress}
                onChange={e => setLocalAddress(e.currentTarget.value)}
            />
            <Button
                bg="#0F77F0"
                width="100%"
                height={{ base: '57px', md: "88px" }}
                borderRadius={{ base: '7px', md: '11px' }}
                color='#FFFFFF'
                fontWeight='600'
                fontSize={{ base: '17px', md: '26px' }}
                textAlign='center'
                onClick={AddAddress}
            >
                Add address
            </Button>
            <Text
                fontWeight="400"
                fontSize={{ base: '10px', md: "15px" }}
                lineHeight={{ base: "12px", md: "18px" }}
                textAlign="center"
                color="#A9B0C3"
                marginTop="10px"
            >
                The tokens reward will be sent to this address, ensure you have your private or seed phrase to unlock your account.
            </Text>
        </Box>
    )
}

export default AddressForm;
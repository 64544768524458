import { Box, Text, Image, Grid, GridItem } from '@chakra-ui/react';

const Recording = () => {
    return (
        <Box>
            <Grid templateColumns='repeat(4, 1fr)' gap={0} height={{base: '80px', md: 'auto'}} padding={{base: "0 20px", md: "0"}} marginTop="20px">
                <GridItem w='100%' colSpan={{ base: 1, md: 4 }}>
                    <Image
                        src='/img/Scan.gif'
                        alt='scan-animation'
                        width="75px"
                        margin={{ base: "0", md: "10px -10px" }}
                    />
                </GridItem>
                <GridItem w='100%' colSpan={{ base: 3, md: 4 }}>
                    <Text
                        fontStyle='normal'
                        fontWeight='bold'
                        fontSize='25px'
                        textAlign="left"
                        display={{base: 'block', md: 'none'}}
                    >
                        Heart Rate AI
                    </Text>
                    <Text
                        fontStyle='normal'
                        fontWeight='400'
                        fontSize={{ base: "16px", sm: "16px", md: '20px' }}
                        textAlign="left"
                    >
                        Processing, please stay still
                    </Text>
                </GridItem>
            </Grid>
        </Box>
    )
}

export default Recording;
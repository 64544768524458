import {
    Box,
    Image,
    Text,
    Button,
    Grid,
    GridItem,
    Center,
    IconButton,
    HStack,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from '@chakra-ui/react'

import React, { useState, useEffect } from 'react';
import { BsCircleFill, BsImage } from 'react-icons/bs';

import { capturedBPM, continueToThankYou, copied12Words } from '../../../utils/facebookPixelEvents';

import useUser from '../../../hooks/useUser';
import useClient from '../../../hooks/useClient';

import ChangeAddressCollapse from '../../../components/ChangeAddressCollapse';

const BpmResult = ({ setVideoRecorderStep, changeAddress }) => {
    const { uuid } = useClient();
    const { words, bpm, age, gender, height, heightMetric, weight, weightMetric, address, hasAddress } = useUser();
    const [isCopied, setisCopied] = useState(false);
    const [showTutorial, setShowTutorial] = useState(false);
    const [page, setPage] = useState(1);
    const [scImage, setSCImage] = useState('');
    const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

    const shortAddres = address.substring(0, 5) + "..." + address.substring(address.length - 4, address.length);

    const mappedWords = words.map((word) =>
        <GridItem w='100%'>
            <Box width="100%" background="#F3F3F3" borderRadius="12px" padding="15px 0">
                <Text fontSize={{ base: '13px', md: '19px' }} fontWeight="400" textAlign="center">{word}</Text>
            </Box>
        </GridItem>
    );

    useEffect(() => {
        localStorage.setItem('bpm', JSON.stringify(bpm));
        capturedBPM();
    }, [bpm]);

    function ChangeStep() {
        setVideoRecorderStep();
    }

    function copySeedPhrase() {
        copied12Words();
        navigator.clipboard.writeText(words.toString());
        setisCopied(true);
        setTimeout(() => setisCopied(false), 2000);
    }

    function openTutorial() {
        setShowTutorial(true);
    }

    function nextPage() {
        if (page === 5) {
            setShowTutorial(false);
            setPage(1);
        } else {
            setPage(page + 1);
        }
    }

    function showSCTutorial(src) {
        setSCImage(src);
        onOpen();
    }

    return (
        <Box width="100%">

            <Grid
                templateAreas={{ base: `"words"`, sm: `"words"`, md: `"words result"` }}
                gridTemplateColumns={{ base: '100%', sm: '100%', md: '60% 40%' }}
                gap='0'
            >
                <GridItem area={'words'} padding="0 20px" display="flex" flexDirection="column" justifyContent="space-between">
                    {hasAddress &&
                        <ChangeAddressCollapse changeAddress={changeAddress} shortAddres={shortAddres} isMobile={false} />
                    }
                    <Image
                        src='/logo.png'
                        alt='logo-image'
                        w="80px"
                        mb="30px"
                        margin={{ base: "0 auto 30px auto", sm: "0 auto 30px auto", md: "0 0 30px 0" }}
                    />
                    <Text
                        fontWeight='bold'
                        fontSize='32px'
                        textAlign={{ base: "center", sm: "center", md: "left" }}
                    >Thank you!</Text><br />
                    {!hasAddress &&
                        <Text
                            fontSize={{ base: '14', md: '25px' }}
                            textAlign={{ base: "center", sm: "center", md: "left" }}
                        >You are all set please enter your Everything is ready, <strong>copy and save the 12 words</strong> and watch the <strong>Metamask</strong> tutorial to receive where we should <strong>send your AHT tokens (ERC-20)</strong></Text>
                    }
                    {hasAddress &&
                        <Text
                            fontSize={{ base: '14', md: '25px' }}
                            textAlign={{ base: "center", sm: "center", md: "left" }}
                        ><strong>Congratulations</strong>, your wallet address has been received.</Text>
                    }<br />
                    {hasAddress &&
                        <ChangeAddressCollapse changeAddress={changeAddress} shortAddres={shortAddres} isMobile={true} />
                    }
                    {!showTutorial &&
                        <Box background="#E3F5FF" height="100%" borderRadius="23px" padding="20px" margin="15px 0" display={{ base: 'block', sm: 'block', md: 'none' }}>
                            <Image
                                src='/img/Confetti.png'
                                alt='logo-image'
                                w={{ base: '60%', sm: "40%" }}
                                margin="auto"
                            />

                            <Grid templateColumns='repeat(2, 1fr)' gap={0}>
                                <GridItem w='100%'>
                                    <Text
                                        fontSize='12px'
                                        color="#1D2556"
                                        textAlign="center"
                                    >Your BPM rate is:</Text>
                                    <Text
                                        fontSize='56px'
                                        color="#1D2556"
                                        textAlign="center"
                                        fontWeight="bold"
                                    >{bpm}<Text fontSize="12px" color="#1D2556" display="inline-block" fontWeight="400">BPM</Text></Text>
                                </GridItem>
                                <GridItem w='100%'>
                                    <Grid templateColumns='repeat(2, 1fr)' gap={1}>
                                        <GridItem w='100%'>
                                            <Text fontSize="10px" fontWeight="400">Age:</Text>
                                            <Box width="100%" background="#FFFFFF" borderRadius="9px" padding="10px" display="flex" justifyContent="space-between">
                                                <Image
                                                    src='/img/Age.png'
                                                    alt='age-icon'
                                                    height="12px"
                                                />
                                                <Text fontSize="12px" fontWeight="bold" color="#1D2556">{age}</Text>
                                            </Box>
                                        </GridItem>
                                        <GridItem w='100%'>
                                            <Text fontSize="10px" fontWeight="400">Gender:</Text>
                                            <Box width="100%" background="#FFFFFF" borderRadius="9px" padding="10px" display="flex" justifyContent="space-between">
                                                <Image
                                                    src='/img/Gender.png'
                                                    alt='gender-icon'
                                                    height="12px"
                                                />
                                                <Text fontSize="12px" fontWeight="bold" color="#1D2556">{gender}</Text>
                                            </Box>
                                        </GridItem>
                                        <GridItem w='100%'>
                                            <Text fontSize="10px" fontWeight="400">Height:</Text>
                                            <Box width="100%" background="#FFFFFF" borderRadius="9px" padding="10px" display="flex" justifyContent="space-between">
                                                <Image
                                                    src='/img/height.svg'
                                                    alt='height-icon'
                                                    height="12px"
                                                />
                                                <Text fontSize="12px" fontWeight="bold" color="#1D2556">{height} {heightMetric}</Text>
                                            </Box>
                                        </GridItem>
                                        <GridItem w='100%'>
                                            <Text fontSize="10px" fontWeight="400">Weight:</Text>
                                            <Box width="100%" background="#FFFFFF" borderRadius="9px" padding="10px" display="flex" justifyContent="space-between">
                                                <Image
                                                    src='/img/weight.svg'
                                                    alt='weight-icon'
                                                    height="12px"
                                                />
                                                <Text fontSize="12px" fontWeight="bold" color="#1D2556">{weight} {weightMetric}</Text>
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                </GridItem>
                            </Grid>
                        </Box>}
                    {showTutorial &&
                        <GridItem area={'result'} display={{ base: 'block', md: 'none' }}>
                            <Box background="#E3F5FF" height="100%" borderRadius="23px" padding="20px">
                                {page === 1 && <Box height="80%">
                                    <Image
                                        src='/img/Tutorial_1.svg'
                                        alt='logo-image'
                                        w="100%"
                                        mb="10px"
                                    />
                                    <Text
                                        fontSize='30px'
                                        fontWeight='800'
                                        color="#1D2556"
                                        textAlign="center">
                                        Use AHT in Heart BPM Analyzer in the Metaverse
                                    </Text>
                                    <Text
                                        padding='15px'
                                        fontSize='20px'
                                        color="#1D2556"
                                        textAlign="center">
                                        Please follow the steps to use the AHT token to Metamask.
                                    </Text>
                                    <Text
                                        padding='15px'
                                        fontSize='15px'
                                        fontWeight='400'
                                        color="#989898"
                                        textAlign="center">
                                        (It is recommended to do the process on a computer)
                                    </Text>
                                </Box>}
                                {page === 2 && <Box height="80%">
                                    <Image
                                        src='/img/Tutorial_2.svg'
                                        alt='logo-image'
                                        w="100%"
                                        mb="10px"
                                    />
                                    <Text
                                        fontSize='29px'
                                        fontWeight='800'
                                        color="#1D2556"
                                        textAlign="center">
                                        What is Metamask and how do I get it?
                                    </Text>
                                    <Text
                                        padding='13px'
                                        fontSize='18px'
                                        color="#1D2556"
                                        textAlign="center">
                                        Metamask is a portable getaway where you can store your crypto assets. Open a browser where Metamask is supported and install the plugin.
                                    </Text>
                                </Box>}
                                {page === 3 && <Box height="80%">
                                    <Image
                                        src='/img/Tutorial_3.svg'
                                        alt='logo-image'
                                        w="100%"
                                        mb="10px"
                                    />
                                    <Text
                                        fontSize='30px'
                                        fontWeight='800'
                                        color="#1D2556"
                                        textAlign="center">
                                        Import your secret recovery phrase
                                    </Text>
                                    <Flex>
                                        <Text padding='1px'
                                            fontSize='15px'
                                            color="#1D2556"
                                            textAlign="left">
                                            1 - During setup from a fresh install of MetaMask, click “Import Wallet”.
                                            <IconButton
                                                color='#0F77F0'
                                                variant='solid'
                                                rounded='full'
                                                colorScheme="#0F77F0"
                                                fontSize='17px'
                                                onClick={() => { showSCTutorial('/img/tutorial_sc_1.svg') }}
                                                icon={<BsImage />} />
                                        </Text>
                                    </Flex>
                                    <Text
                                        padding='1px'
                                        fontSize='15px'
                                        color="#1D2556"
                                        textAlign="left">
                                        2 - Enter your Secret Recovery Phrase and follow the prompts.
                                        <IconButton
                                            color='#0F77F0'
                                            variant='solid'
                                            rounded='full'
                                            colorScheme="#0F77F0"
                                            fontSize='17px'
                                            onClick={() => { showSCTutorial('/img/tutorial_sc_2.svg') }}
                                            icon={<BsImage />} />
                                    </Text>
                                    <Text
                                        padding='1px'
                                        fontSize='15px'
                                        color="#1D2556"
                                        textAlign="left">
                                        3 - Add your private key pasted from the app. Find your recovery seed in Profile{'>'}Backup.
                                        <IconButton
                                            color='#0F77F0'
                                            variant='solid'
                                            rounded='full'
                                            colorScheme="#0F77F0"
                                            fontSize='17px'
                                            onClick={() => { showSCTutorial('/img/tutorial_sc_3.svg') }}
                                            icon={<BsImage />} />
                                    </Text>
                                    <Text
                                        padding='1px'
                                        fontSize='15px'
                                        color="#1D2556"
                                        textAlign="left">
                                        4 - After pasting the private key, click on the import button.
                                        <IconButton
                                            color='#0F77F0'
                                            variant='solid'
                                            rounded='full'
                                            colorScheme="#0F77F0"
                                            fontSize='17px'
                                            onClick={() => { showSCTutorial('/img/tutorial_sc_4.svg') }}
                                            icon={<BsImage />} />
                                    </Text>
                                </Box>}
                                {page === 4 && <Box height="80%">
                                    <Image
                                        src='/img/Tutorial_4.svg'
                                        alt='logo-image'
                                        w="100%"
                                        mb="10px"
                                    />
                                    <Text
                                        fontSize='25px'
                                        fontWeight='800'
                                        color="#1D2556"
                                        textAlign="center">
                                        Switch to the Polygon network
                                    </Text>
                                    <Text
                                        padding='1px'
                                        fontSize='14px'
                                        color="#1D2556"
                                        textAlign="left">
                                        1 - Click on the network selector located at the left of the identicon and then click on the “Add network” button.
                                        <IconButton
                                            color='#0F77F0'
                                            variant='solid'
                                            rounded='full'
                                            colorScheme="#0F77F0"
                                            fontSize='17px'
                                            onClick={() => { showSCTutorial('/img/tutorial_sc_5.svg') }}
                                            icon={<BsImage />} />
                                    </Text>
                                    <Text
                                        padding='1px'
                                        fontSize='14px'
                                        color="#1D2556"
                                        textAlign="left">
                                        2 - On the “Add a network” panel, click on the “Add” link next to Polygon Main-net and press the approve button.
                                        <IconButton
                                            color='#0F77F0'
                                            variant='solid'
                                            rounded='full'
                                            colorScheme="#0F77F0"
                                            fontSize='17px'
                                            onClick={() => { showSCTutorial('/img/tutorial_sc_6.svg') }}
                                            icon={<BsImage />} />
                                    </Text>
                                    <Text
                                        padding='1px'
                                        fontSize='14px'
                                        color="#1D2556"
                                        textAlign="left">
                                        3 - Click on the “Switch to Polygon Mainnet” button.
                                        <IconButton
                                            color='#0F77F0'
                                            variant='solid'
                                            rounded='full'
                                            colorScheme="#0F77F0"
                                            fontSize='17px'
                                            onClick={() => { showSCTutorial('/img/tutorial_sc_7.svg') }}
                                            icon={<BsImage />} />
                                    </Text>
                                    <Text
                                        padding='1px'
                                        fontSize='14px'
                                        color="#1D2556"
                                        textAlign="left">
                                        4 - Click on the “Got it” button.
                                        <IconButton
                                            color='#0F77F0'
                                            variant='solid'
                                            rounded='full'
                                            colorScheme="#0F77F0"
                                            fontSize='17px'
                                            onClick={() => { showSCTutorial('/img/tutorial_sc_8.svg') }}
                                            icon={<BsImage />} />
                                    </Text>
                                    <Text
                                        padding='1px'
                                        fontSize='14px'
                                        color="#1D2556"
                                        textAlign="left">
                                        5 - After switching, Metamask will show the amount of Matic you have, which is the Polygon’s native currency.
                                        <IconButton
                                            color='#0F77F0'
                                            variant='solid'
                                            rounded='full'
                                            colorScheme="#0F77F0"
                                            fontSize='17px'
                                            onClick={() => { showSCTutorial('/img/tutorial_sc_9.svg') }}
                                            icon={<BsImage />} />
                                    </Text>
                                </Box>}
                                {page === 5 && <Box height="80%" alignItems="center">
                                    <Image
                                        src='/img/Tutorial_5.svg'
                                        alt='logo-image'
                                        w="100%"
                                        mb="10px"
                                    />
                                    <Text
                                        fontSize='30px'
                                        fontWeight='800'
                                        color="#1D2556"
                                        textAlign="center">
                                        Add AHT token
                                    </Text>
                                    <Text
                                        padding='1px'
                                        fontSize='15px'
                                        color="#1D2556"
                                        textAlign="left">
                                        1 - Click on the “Import Tokens” link located at the bottom of the window.
                                        <IconButton
                                            color='#0F77F0'
                                            variant='solid'
                                            rounded='full'
                                            colorScheme="#0F77F0"
                                            fontSize='17px'
                                            onClick={() => { showSCTutorial('/img/tutorial_sc_10.svg') }}
                                            icon={<BsImage />} />
                                    </Text>
                                    <Text
                                        padding='1px'
                                        fontSize='15px'
                                        color="#1D2556"
                                        textAlign="left">
                                        2 - Enter the next that in the form:
                                        <IconButton
                                            color='#0F77F0'
                                            variant='solid'
                                            rounded='full'
                                            colorScheme="#0F77F0"
                                            fontSize='17px'
                                            onClick={() => { showSCTutorial('/img/tutorial_sc_11.svg') }}
                                            icon={<BsImage />} />
                                    </Text>
                                    <Text
                                        padding='10px'
                                        paddingLeft='1.5rem'
                                        fontSize='15px'
                                        color="#1D2556"
                                        textAlign="left">
                                        <ul>
                                            <li>Token contract address: 0x...</li>
                                            <li> Token symbol: AHT</li>
                                            <li>Token decimals: 18</li>
                                        </ul>
                                    </Text>
                                    <Text
                                        padding='10px'
                                        fontSize='15px'
                                        color="#1D2556"
                                        textAlign="left">
                                        3 - Click on the “Add custom token” to add the AHT token to your wallet.
                                    </Text>
                                </Box>}
                                <Modal isOpen={isOpen} onClose={onClose}>
                                    <ModalOverlay />
                                    <ModalContent containerProps={{ justifyContent: 'flex-end', paddingRight: '7rem' }}>
                                        <ModalCloseButton />
                                        <ModalBody>
                                            <Image src={scImage}></Image>
                                        </ModalBody>
                                    </ModalContent>
                                </Modal>
                                <Box height="7%">
                                    <Center>
                                        <HStack>
                                            <IconButton
                                                color={page === 1 ? '#70A4BE' : '#C8EDFF'}
                                                variant='solid'
                                                rounded='full'
                                                colorScheme="#70A4BE"
                                                fontSize='13px'
                                                onClick={() => setPage(1)}
                                                icon={<BsCircleFill />} />
                                            <IconButton
                                                color={page === 2 ? '#70A4BE' : '#C8EDFF'}
                                                variant='solid'
                                                rounded='full'
                                                colorScheme="#70A4BE"
                                                fontSize='13px'
                                                onClick={() => setPage(2)}
                                                icon={<BsCircleFill />} />
                                            <IconButton
                                                color={page === 3 ? '#70A4BE' : '#C8EDFF'}
                                                variant='solid'
                                                rounded='full'
                                                colorScheme="#70A4BE"
                                                fontSize='13px'
                                                onClick={() => setPage(3)}
                                                icon={<BsCircleFill />} />
                                            <IconButton
                                                color={page === 4 ? '#70A4BE' : '#C8EDFF'}
                                                variant='solid'
                                                rounded='full'
                                                colorScheme="#70A4BE"
                                                fontSize='13px'
                                                onClick={() => setPage(4)}
                                                icon={<BsCircleFill />} />
                                            <IconButton
                                                color={page === 5 ? '#70A4BE' : '#C8EDFF'}
                                                variant='solid'
                                                rounded='full'
                                                colorScheme="#70A4BE"
                                                fontSize='13px'
                                                onClick={() => setPage(5)}
                                                icon={<BsCircleFill />} />
                                        </HStack>
                                    </Center>
                                </Box>
                                <Box height="13%">
                                    <Button onClick={nextPage} width="100%" height={{ base: "61px", md: "81px" }} background="#0F77F0" color="#FFFFFF" borderRadius="13px" fontSize={{ base: "19px", md: "23px" }}> {page === 5 ? 'Done' : 'Continue'}</Button>
                                </Box>
                            </Box>
                        </GridItem>
                    }
                    {!hasAddress &&
                        <Grid templateColumns='repeat(4, 1fr)' gap={{ base: 1, sm: 1, md: 6 }}>
                            {mappedWords}
                            <Button
                                onClick={copySeedPhrase}
                                borderRadius="10px"
                                background="rgba(15, 119, 240, 0.13)"
                                width="100%"
                                height={{base: "42px", md: "62px"}}
                                fontSize={{ base: '13px', md: '19px' }}
                                fontWeight="400"
                                textAlign="center"
                                color="#0F77F0"
                            >
                                Copy
                            </Button>
                            {isCopied && <Text padding="15px 0" color="#FF0000">12 words copied</Text>}
                        </Grid>}<br />
                    <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={2}>
                        {(!showTutorial || !hasAddress) && <GridItem w='100%'>

                            <Button onClick={openTutorial} width="100%" height={{ base: "61px", md: "81px" }} background="#0F77F0" color="#FFFFFF" borderRadius="13px" fontSize={{ base: "19px", md: "23px" }}>Metamask tutorial</Button>
                        </GridItem>}
                        <GridItem w='100%'>
                            <Button onClick={() => { continueToThankYou(); ChangeStep(); }} width="100%" height={{ base: "61px", md: "81px" }} background="#EFEFEF" color="#0F77F0" borderRadius="13px" fontSize={{ base: "19px", md: "23px" }}>Continue</Button>
                        </GridItem>
                    </Grid>
                </GridItem>
                {!showTutorial &&
                    <GridItem area={'result'} padding="0 20px" display={{ base: 'none', sm: 'none', md: 'block' }}>
                        <Box background="#E3F5FF" height="100%" borderRadius="23px" padding="20px">
                            <Image
                                src='/img/Confetti.png'
                                alt='logo-image'
                                w="100%"
                                mb="10px"
                            />
                            <Text
                                fontSize='32px'
                                color="#1D2556"
                                textAlign="center"
                            >Your BPM rate is:</Text>
                            <Text
                                fontSize='150px'
                                color="#1D2556"
                                textAlign="center"
                                fontWeight="bold"
                            >{bpm}<Text fontSize="31px" color="#1D2556" display="inline-block" fontWeight="400">BPM</Text></Text>
                            <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                                <GridItem w='100%'>
                                    <Text fontSize="26px" fontWeight="400">Age:</Text>
                                    <Box width="100%" background="#FFFFFF" borderRadius="9px" padding="10px" display="flex" justifyContent="space-between">
                                        <Image
                                            src='/img/Age.png'
                                            alt='age-icon'
                                            height="100%"
                                        />
                                        <Text fontSize="26px" fontWeight="bold" color="#1D2556">{age}</Text>
                                    </Box>
                                </GridItem>
                                <GridItem w='100%'>
                                    <Text fontSize="26px" fontWeight="400">Gender:</Text>
                                    <Box width="100%" background="#FFFFFF" borderRadius="9px" padding="10px" display="flex" justifyContent="space-between">
                                        <Image
                                            src='/img/Gender.png'
                                            alt='gender-icon'
                                            height="100%"
                                        />
                                        <Text fontSize="26px" fontWeight="bold" color="#1D2556">{gender}</Text>
                                    </Box>
                                </GridItem>
                                <GridItem w='100%'>
                                    <Text fontSize="26px" fontWeight="400">Height:</Text>
                                    <Box width="100%" background="#FFFFFF" borderRadius="9px" padding="10px" display="flex" justifyContent="space-between">
                                        <Image
                                            paddingTop='5px'
                                            src='/img/height.svg'
                                            alt='height-icon'
                                            height="100%"
                                        />
                                        <Text fontSize="26px" fontWeight="bold" color="#1D2556">{height} {heightMetric}</Text>
                                    </Box>
                                </GridItem>
                                <GridItem w='100%'>
                                    <Text fontSize="26px" fontWeight="400">Weight:</Text>
                                    <Box width="100%" background="#FFFFFF" borderRadius="9px" padding="10px" display="flex" justifyContent="space-between">
                                        <Image
                                            paddingTop='5px'
                                            src='/img/weight.svg'
                                            alt='weight-icon'
                                            height="100%"
                                        />
                                        <Text fontSize="26px" fontWeight="bold" color="#1D2556">{weight} {weightMetric}</Text>
                                    </Box>
                                </GridItem>
                            </Grid>
                        </Box>
                    </GridItem>}
                {showTutorial &&
                    <GridItem area={'result'} padding="0 20px" display={{ base: 'none', sm: 'none', md: 'block' }}>
                        <Box background="#E3F5FF" height="100%" borderRadius="23px" padding="20px">
                            {page === 1 && <Box height="80%">
                                <Image
                                    src='/img/Tutorial_1.svg'
                                    alt='logo-image'
                                    w="100%"
                                    mb="10px"
                                />
                                <Text
                                    fontSize='30px'
                                    fontWeight='800'
                                    color="#1D2556"
                                    textAlign="center">
                                    Use AHT in Heart BPM Analyzer in the Metaverse
                                </Text>
                                <Text
                                    padding='15px'
                                    fontSize='20px'
                                    color="#1D2556"
                                    textAlign="center">
                                    Please follow the steps to use the AHT token to Metamask.
                                </Text>
                                <Text
                                    padding='15px'
                                    fontSize='15px'
                                    fontWeight='400'
                                    color="#989898"
                                    textAlign="center">
                                    (It is recommended to do the process on a computer)
                                </Text>
                            </Box>}
                            {page === 2 && <Box height="80%">
                                <Image
                                    src='/img/Tutorial_2.svg'
                                    alt='logo-image'
                                    w="100%"
                                    mb="10px"
                                />
                                <Text
                                    fontSize='29px'
                                    fontWeight='800'
                                    color="#1D2556"
                                    textAlign="center">
                                    What is Metamask and how do I get it?
                                </Text>
                                <Text
                                    padding='13px'
                                    fontSize='18px'
                                    color="#1D2556"
                                    textAlign="center">
                                    Metamask is a portable getaway where you can store your crypto assets. Open a browser where Metamask is supported and install the plugin.
                                </Text>
                            </Box>}
                            {page === 3 && <Box height="80%">
                                <Image
                                    src='/img/Tutorial_3.svg'
                                    alt='logo-image'
                                    w="100%"
                                    mb="10px"
                                />
                                <Text
                                    fontSize='30px'
                                    fontWeight='800'
                                    color="#1D2556"
                                    textAlign="center">
                                    Import your secret recovery phrase
                                </Text>
                                <Flex>
                                    <Text padding='1px'
                                        fontSize='15px'
                                        color="#1D2556"
                                        textAlign="left">
                                        1 - During setup from a fresh install of MetaMask, click “Import Wallet”.
                                        <IconButton
                                            color='#0F77F0'
                                            variant='solid'
                                            rounded='full'
                                            colorScheme="#0F77F0"
                                            fontSize='17px'
                                            onClick={() => { showSCTutorial('/img/tutorial_sc_1.svg') }}
                                            icon={<BsImage />} />
                                    </Text>
                                </Flex>
                                <Text
                                    padding='1px'
                                    fontSize='15px'
                                    color="#1D2556"
                                    textAlign="left">
                                    2 - Enter your Secret Recovery Phrase and follow the prompts.
                                    <IconButton
                                        color='#0F77F0'
                                        variant='solid'
                                        rounded='full'
                                        colorScheme="#0F77F0"
                                        fontSize='17px'
                                        onClick={() => { showSCTutorial('/img/tutorial_sc_2.svg') }}
                                        icon={<BsImage />} />
                                </Text>
                                <Text
                                    padding='1px'
                                    fontSize='15px'
                                    color="#1D2556"
                                    textAlign="left">
                                    3 - Add your private key pasted from the app. Find your recovery seed in Profile{'>'}Backup.
                                    <IconButton
                                        color='#0F77F0'
                                        variant='solid'
                                        rounded='full'
                                        colorScheme="#0F77F0"
                                        fontSize='17px'
                                        onClick={() => { showSCTutorial('/img/tutorial_sc_3.svg') }}
                                        icon={<BsImage />} />
                                </Text>
                                <Text
                                    padding='1px'
                                    fontSize='15px'
                                    color="#1D2556"
                                    textAlign="left">
                                    4 - After pasting the private key, click on the import button.
                                    <IconButton
                                        color='#0F77F0'
                                        variant='solid'
                                        rounded='full'
                                        colorScheme="#0F77F0"
                                        fontSize='17px'
                                        onClick={() => { showSCTutorial('/img/tutorial_sc_4.svg') }}
                                        icon={<BsImage />} />
                                </Text>
                            </Box>}
                            {page === 4 && <Box height="80%">
                                <Image
                                    src='/img/Tutorial_4.svg'
                                    alt='logo-image'
                                    w="100%"
                                    mb="10px"
                                />
                                <Text
                                    fontSize='25px'
                                    fontWeight='800'
                                    color="#1D2556"
                                    textAlign="center">
                                    Switch to the Polygon network
                                </Text>
                                <Text
                                    padding='1px'
                                    fontSize='14px'
                                    color="#1D2556"
                                    textAlign="left">
                                    1 - Click on the network selector located at the left of the identicon and then click on the “Add network” button.
                                    <IconButton
                                        color='#0F77F0'
                                        variant='solid'
                                        rounded='full'
                                        colorScheme="#0F77F0"
                                        fontSize='17px'
                                        onClick={() => { showSCTutorial('/img/tutorial_sc_5.svg') }}
                                        icon={<BsImage />} />
                                </Text>
                                <Text
                                    padding='1px'
                                    fontSize='14px'
                                    color="#1D2556"
                                    textAlign="left">
                                    2 - On the “Add a network” panel, click on the “Add” link next to Polygon Main-net and press the approve button.
                                    <IconButton
                                        color='#0F77F0'
                                        variant='solid'
                                        rounded='full'
                                        colorScheme="#0F77F0"
                                        fontSize='17px'
                                        onClick={() => { showSCTutorial('/img/tutorial_sc_6.svg') }}
                                        icon={<BsImage />} />
                                </Text>
                                <Text
                                    padding='1px'
                                    fontSize='14px'
                                    color="#1D2556"
                                    textAlign="left">
                                    3 - Click on the “Switch to Polygon Mainnet” button.
                                    <IconButton
                                        color='#0F77F0'
                                        variant='solid'
                                        rounded='full'
                                        colorScheme="#0F77F0"
                                        fontSize='17px'
                                        onClick={() => { showSCTutorial('/img/tutorial_sc_7.svg') }}
                                        icon={<BsImage />} />
                                </Text>
                                <Text
                                    padding='1px'
                                    fontSize='14px'
                                    color="#1D2556"
                                    textAlign="left">
                                    4 - Click on the “Got it” button.
                                    <IconButton
                                        color='#0F77F0'
                                        variant='solid'
                                        rounded='full'
                                        colorScheme="#0F77F0"
                                        fontSize='17px'
                                        onClick={() => { showSCTutorial('/img/tutorial_sc_8.svg') }}
                                        icon={<BsImage />} />
                                </Text>
                                <Text
                                    padding='1px'
                                    fontSize='14px'
                                    color="#1D2556"
                                    textAlign="left">
                                    5 - After switching, Metamask will show the amount of Matic you have, which is the Polygon’s native currency.
                                    <IconButton
                                        color='#0F77F0'
                                        variant='solid'
                                        rounded='full'
                                        colorScheme="#0F77F0"
                                        fontSize='17px'
                                        onClick={() => { showSCTutorial('/img/tutorial_sc_9.svg') }}
                                        icon={<BsImage />} />
                                </Text>
                            </Box>}
                            {page === 5 && <Box height="80%" alignItems="center">
                                <Image
                                    src='/img/Tutorial_5.svg'
                                    alt='logo-image'
                                    w="100%"
                                    mb="10px"
                                />
                                <Text
                                    fontSize='30px'
                                    fontWeight='800'
                                    color="#1D2556"
                                    textAlign="center">
                                    Add AHT token
                                </Text>
                                <Text
                                    padding='1px'
                                    fontSize='15px'
                                    color="#1D2556"
                                    textAlign="left">
                                    1 - Click on the “Import Tokens” link located at the bottom of the window.
                                    <IconButton
                                        color='#0F77F0'
                                        variant='solid'
                                        rounded='full'
                                        colorScheme="#0F77F0"
                                        fontSize='17px'
                                        onClick={() => { showSCTutorial('/img/tutorial_sc_10.svg') }}
                                        icon={<BsImage />} />
                                </Text>
                                <Text
                                    padding='1px'
                                    fontSize='15px'
                                    color="#1D2556"
                                    textAlign="left">
                                    2 - Enter the next that in the form:
                                    <IconButton
                                        color='#0F77F0'
                                        variant='solid'
                                        rounded='full'
                                        colorScheme="#0F77F0"
                                        fontSize='17px'
                                        onClick={() => { showSCTutorial('/img/tutorial_sc_11.svg') }}
                                        icon={<BsImage />} />
                                </Text>
                                <Text
                                    padding='10px'
                                    paddingLeft='1.5rem'
                                    fontSize='15px'
                                    color="#1D2556"
                                    textAlign="left">
                                    <ul>
                                        <li>Token contract address: 0x...</li>
                                        <li> Token symbol: AHT</li>
                                        <li>Token decimals: 18</li>
                                    </ul>
                                </Text>
                                <Text
                                    padding='10px'
                                    fontSize='15px'
                                    color="#1D2556"
                                    textAlign="left">
                                    3 - Click on the “Add custom token” to add the AHT token to your wallet.
                                </Text>
                            </Box>}
                            <Modal isOpen={isOpen} onClose={onClose}>
                                <ModalOverlay />
                                <ModalContent containerProps={{ justifyContent: 'flex-end', paddingRight: '7rem' }}>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Image src={scImage}></Image>
                                    </ModalBody>
                                </ModalContent>
                            </Modal>
                            <Box height="7%">
                                <Center>
                                    <HStack>
                                        <IconButton
                                            color={page === 1 ? '#70A4BE' : '#C8EDFF'}
                                            variant='solid'
                                            rounded='full'
                                            colorScheme="#70A4BE"
                                            fontSize='13px'
                                            onClick={() => setPage(1)}
                                            icon={<BsCircleFill />} />
                                        <IconButton
                                            color={page === 2 ? '#70A4BE' : '#C8EDFF'}
                                            variant='solid'
                                            rounded='full'
                                            colorScheme="#70A4BE"
                                            fontSize='13px'
                                            onClick={() => setPage(2)}
                                            icon={<BsCircleFill />} />
                                        <IconButton
                                            color={page === 3 ? '#70A4BE' : '#C8EDFF'}
                                            variant='solid'
                                            rounded='full'
                                            colorScheme="#70A4BE"
                                            fontSize='13px'
                                            onClick={() => setPage(3)}
                                            icon={<BsCircleFill />} />
                                        <IconButton
                                            color={page === 4 ? '#70A4BE' : '#C8EDFF'}
                                            variant='solid'
                                            rounded='full'
                                            colorScheme="#70A4BE"
                                            fontSize='13px'
                                            onClick={() => setPage(4)}
                                            icon={<BsCircleFill />} />
                                        <IconButton
                                            color={page === 5 ? '#70A4BE' : '#C8EDFF'}
                                            variant='solid'
                                            rounded='full'
                                            colorScheme="#70A4BE"
                                            fontSize='13px'
                                            onClick={() => setPage(5)}
                                            icon={<BsCircleFill />} />
                                    </HStack>
                                </Center>
                            </Box>
                            <Box height="13%">
                                <Button onClick={nextPage} width="100%" height={{ base: "61px", md: "81px" }} background="#0F77F0" color="#FFFFFF" borderRadius="13px" fontSize={{ base: "19px", md: "23px" }}> {page === 5 ? 'Done' : 'Continue'}</Button>
                            </Box>
                        </Box>
                    </GridItem>
                }
            </Grid>
        </Box>

    )
}

export default BpmResult;
import { createContext, useState } from "react";

const UserContext = createContext();

const UserProvider = ({children}) => {
    const [age, setAge] = useState(null);
    const [gender, setGender] = useState('Male');
    const [weight, setWeight] = useState('');
    const [weightMetric, setWeightMetric] = useState('--');
    const [height, setHeight] = useState('');
    const [heightMetric, setHeightMetric] = useState('--');
    const [words, setWords] = useState([]);
    const [address, setAddress] = useState('');
    const [hasAddress, setHasAddress] = useState(false);
    const [countryCode, setCountryCode] = useState('');
    const [bpm, setRecordedBPM] = useState('');

    return (
        <UserContext.Provider
            value={{
                age,
                gender,
                weight,
                weightMetric,
                height,
                heightMetric,
                words,
                address,
                hasAddress,
                countryCode,
                bpm,
                setAge,
                setGender,
                setWeight,
                setWeightMetric,
                setHeight,
                setHeightMetric,
                setWords,
                setAddress,
                setHasAddress,
                setCountryCode,
                setRecordedBPM
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export { UserProvider };
export default UserContext;
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    useDisclosure,
    Button
} from '@chakra-ui/react';
import { useEffect } from 'react';

const BaseModal = ({ handleClick, buttonText, isOpenModal, children }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        if (isOpenModal) onOpen();
      }, [isOpenModal]);

    function CloseModal() {
        if (handleClick) handleClick();
        onClose();
    }

    return (
        <>
            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody pb={6}>
                        {children}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' onClick={CloseModal} width="100%" height="81px" borderRadius="13px">
                            {buttonText ? buttonText : 'Continue'}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default BaseModal;
import { Box, Text } from '@chakra-ui/react';

const Stopped = () => {
    return (
        <Box>
            <Text
                fontStyle='normal'
                fontWeight='400'
                fontSize={{base: "16px", sm: "16px", md: '20px' }}
                textAlign={{base: "center", sm: "center", md: "left" }}
                margin={{ base: "35px 0", sm: "35px 0", md: "0" }}
            >
                Please tap on <strong>"Resume"</strong> to continue reading your BPM
            </Text>
        </Box>
    )
}

export default Stopped;
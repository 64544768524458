import {
    Box,
    Image,
    Text,
    Button,
    useDisclosure,
    Collapse
} from '@chakra-ui/react'

const ChangeAddressCollapse = ({ shortAddres, changeAddress, isMobile }) => {
    const { isOpen, onToggle } = useDisclosure()

    return (
        <Box width="100%" position={{ base: 'static', md: 'relative' }} display={isMobile ? { base: 'block', md: 'none' } : { base: 'none', md: 'block' }} >
            <Box position={{ base: 'static', md: 'absolute' }} right='0'>
                <Text
                    background="#F3F3F3"
                    borderRadius="16px"
                    padding="10px 20px"
                    onClick={onToggle}
                    _hover={{ cursor: "pointer" }}
                    marginBottom="10px"
                    textAlign="center"
                >
                    Account (
                    {shortAddres}
                    )
                </Text>
                <Collapse in={isOpen} animateOpacity>
                    <Box padding="5px"><Button
                        borderRadius="16px"
                        width="100%"
                        background="#FFFFFF"
                        boxShadow="md"
                        color="#000000"
                        onClick={changeAddress}
                    >
                        Change address
                    </Button></Box>
                </Collapse>
            </Box>
        </Box>
    )
}

export default ChangeAddressCollapse;
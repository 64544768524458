import { Box, Text, Image, Button, Grid, GridItem } from '@chakra-ui/react';
import { etherNo, etherYes } from '../../../../utils/facebookPixelEvents';

const HaveAddress = ({ setAddressForm, setVideoRecorderStep }) => {
    return (
        <Box>
            <Image
                src='/logo.png'
                alt='logo-image'
                w={{base: '60px', md: "80px"}}
                margin="30px auto"
            />
            <Text
                fontWeight="600"
                fontSize={{base: "25px", md: "40px"}}
                lineHeight={{base: "31px", md: "48px"}}
                textAlign="center"
            >
                Do you have an Ethereum address?
            </Text>
            <Text
                fontWeight="400"
                fontSize={{base: "17px", md: "26px"}}
                textAlign="center"
                color="#C2C2C2"
                marginBottom="40px"
            >
                Metamask recommended
            </Text>
            <Grid templateColumns='repeat(2, 1fr)' gap={2}>
                <GridItem w='100%'>
                    <Button
                        bg="#E7E9ED"
                        width="100%"
                        height={{base: '57px', md: "88px"}}
                        borderRadius={{base: '7px',md: '11px'}}
                        color='#000000'
                        fontWeight='600'
                        fontSize={{base: '24px', md: '37px'}}
                        textAlign='center'
                        onClick={() => { etherNo(); setVideoRecorderStep(); }}
                    >
                        No
                    </Button>
                </GridItem>
                <GridItem w='100%'>
                    <Button
                        bg="#0F77F0"
                        width="100%"
                        height={{base: '57px', md: "88px"}}
                        borderRadius={{base: '7px',md: '11px'}}
                        color='#FFFFFF'
                        fontStyle='normal'
                        fontWeight='600'
                        fontSize={{base: '24px', md: '37px'}}
                        textAlign='center'
                        onClick={() => {etherYes(); setAddressForm();}}
                    >
                        Yes
                    </Button>
                </GridItem>
            </Grid>
        </Box>
    )
}

export default HaveAddress;
import {
    Box,
    Image,
    Text,
    Button,
    Grid,
    GridItem,
    useRadio,
    useRadioGroup,
    Modal,
    ModalOverlay,
    ModalContent,
    Center,
    CircularProgress,
    useDisclosure,
} from '@chakra-ui/react'

import { ethers } from 'ethers';
import { v4 as uuidv4 } from 'uuid';

import useUser from '../../../hooks/useUser';
import useClient from '../../../hooks/useClient';
import { useState } from 'react';

import ProgressBar from '../../../components/progress-bar/ProgressBar';

const WhatGenderAreYou = ({ setStep }) => {
    const { uuid } = useClient();
    const { age, height, heightMetric, weight, weightMetric, gender, setGender, setWords, setAddress, countryCode, setCountryCode } = useUser();
    const { updateToken, getUserLocation, saveProfile, saveDevice } = useClient();
    const { isOpen } = useDisclosure();

    const [isLoading, setIsLoading] = useState(false);

    const content = {
        body: 'What gender are you?',
        button: 'Continue'
    }

    async function ChangeStep() {
        if (!isLoading) {
            setIsLoading(true);

            const address = newWallet();
            setAddress(address);
            await updateToken();
            await newDevice(address);
            await newProfile(address);

            setIsLoading(false);
            setStep();
        }
    }

    function newWallet() {
        let wallet = ethers.Wallet.createRandom();
        let phrase = wallet.mnemonic.phrase;
        let newWords = phrase.split(' ');
        setWords(newWords);
        localStorage.setItem('words', JSON.stringify(newWords));

        return wallet.address;
    }

    async function hash(string) {
        const utf8 = new TextEncoder().encode(string);
        const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
            .map((bytes) => bytes.toString(16).padStart(2, '0'))
            .join('');

        return hashHex;
    }

    async function newDevice(address) {
        const device = {
            'address': address,
            'device': '.',
            'lang': 'en',
            'uuid': uuidv4(),
            'hash': await hash(address)
        };
        await saveDevice(device);
    }

    async function newProfile(address) {
        try {
            const profile = {
                'address': address,
                'uuid': uuid,
                'gender': gender,
                'nickname': address.substring(address.length - 6),
                'age': age,
                'weight': weight,
                'height': height,
                'heightMetric': heightMetric,
                'weightMetric': weightMetric,
                'countryCode': countryCode
            }

            localStorage.setItem('data', JSON.stringify(profile));
            await saveProfile(profile);
        } catch (error) {
            console.error(error);
            console.log('Something wrong happend');
        }
    }

    const CustomCheckbox = (props) => {
        const { getInputProps, getCheckboxProps } = useRadio(props)

        const input = getInputProps()
        const checkbox = getCheckboxProps()

        return (
            <Box as='label'>
                <input {...input} />
                <Box
                    {...checkbox}
                    cursor='pointer'
                    borderWidth='1px'
                    borderRadius='10px'
                    borderColor="#CFD2D9"
                    _checked={{
                        borderColor: props.color,
                    }}
                    padding="30px 0"
                >
                    {props.children}
                </Box>
            </Box>
        )
    }

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'framework',
        defaultValue: 'Male',
        onChange: e => setGender(e),
    })

    const group = getRootProps();
    const options = [
        { text: 'Male', value: 'Male', image: "/img/MaleIcon.png", color: "#4B9EFF" },
        { text: 'Female', value: 'Female', image: "/img/FemaleIcon.png", color: "#FF4F8E" },
        { text: 'Other', value: 'Non  binary', image: "/img/OtherIcon.png", color: "#B77EFF" }]

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" height={{ base: "92%", md: "90%" }}>
            <Image
                src='/logo.png'
                alt='logo-image'
                margin="30px auto"
                width={{ md: "auto", base: "80px" }}
            />
            <Text
                fontStyle='normal'
                fontWeight='bold'
                fontSize={{ base: "30px", md: '40px' }}
                textAlign='center'
            >
                {content.body}
            </Text>

            <Grid templateColumns='repeat(3, 1fr)' gap={2} {...group}>
                {options.map((value) => {
                    const radio = getRadioProps({ value: value.value })
                    return (
                        <GridItem w='100%' key={value.value}>
                            <CustomCheckbox key={value.text} text={value.text} image={value.image} color={value.color} {...radio} >
                                <Image src={value.image} height={{ base: "50px", md: "70px" }} margin="20px auto" />
                                <Text
                                    fontWeight="600"
                                    textAlign="center"
                                >
                                    {value.text}
                                </Text>
                            </CustomCheckbox>
                        </GridItem>
                    )
                })}
            </Grid>

            <Modal isOpen={isOpen}>
                <ModalOverlay />
                <ModalContent width="100px" position="absolute" top="50%" marginTop="-100px">
                    <Center>
                        <CircularProgress isIndeterminate size='100px' thickness='8px'></CircularProgress>
                    </Center>
                </ModalContent>
            </Modal>

            <Button bg="#0F77F0" width="100%" height="81px" borderRadius='13px' color='#ffffff'
                fontStyle='normal'
                fontWeight='600'
                fontSize='26.17px'
                lineHeight='31px'
                textAlign='center'
                onClick={ChangeStep}
                _hover={{ background: "#0F77F0" }}
            >
                <ProgressBar isLoading={isLoading} />
                {isLoading ? 'Processing...' : 'Continue'}
            </Button>
        </Box>

    )
}

export default WhatGenderAreYou;
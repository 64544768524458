import {
  Box,
  Image,
  Text,
  Button,
  Grid,
  GridItem,
  CircularProgress,
  Center,
  ModalOverlay,
  Modal,
  ModalContent,
  useDisclosure,
} from '@chakra-ui/react'

import { allowCamera, blockCamera, cameraEnabled, sharedVideo } from '../../../utils/facebookPixelEvents';

import { FaceMesh } from "@mediapipe/face_mesh";
import * as Facemesh from "@mediapipe/face_mesh";
import * as cam from "@mediapipe/camera_utils";

import io from 'socket.io-client';
import React, { useState, useEffect } from 'react';
import Webcam from 'react-webcam';

import Starting from './heart-rate-ia-body/Starting';
import Recording from './heart-rate-ia-body/Recording';
import Stopped from './heart-rate-ia-body/Stopped';
import Error from './heart-rate-ia-body/Error';
import useClient from '../../../hooks/useClient';
import useUser from '../../../hooks/useUser';

import ProgressBar from '../../../components/progress-bar/ProgressBar';
import BaseModal from '../../../components/Modal';

const socket = io.connect(process.env.REACT_APP_BPM_ANALYZER_ENDPOINT, {
  withCredentials: true,
  transport: ['websocket']
});

const VideoRecorder = ({ setVideoRecorderStep }) => {
  const webcamRef = React.useRef(null);
  const canvasRef = React.useRef(null);
  const canvasFaceRef = React.useRef(null);
  const canvasMeshRef = React.useRef(null);
  const connect = window.drawConnectors;
  var camera = null;

  const roiAvgValues = React.useRef([]);
  const times = React.useRef([]);
  const lastBpm = React.useRef(0);
  const mediaRecorderRef = React.useRef(null);
  const meshMediaRecorderRef = React.useRef(null);

  const [isActiveCamera, setIsActiveCamera] = useState(false);

  const [button, setButton] = useState({ text: 'Enable camera and start', background: '#0F77F0' });
  const [body, setBody] = useState(<Starting />);
  const [status, setStatus] = useState('Starting');
  const [isLoading, setIsLoading] = useState(false);
  const [scannerError, setScanerError] = useState('Try focusing better on your face, improving the lighting and improving the quality of the camera.');

  const [image, setImage] = useState('/img/CameraOff.png');

  const [bpm, setBpm] = useState(0);
  const [bpmFontSize, setBpmFontSize] = useState('32px');
  const [bpmArray, setBpmArray] = useState([]);
  const [isCalibrated, setIsCalibrated] = useState(false);
  const [calibrationFinish, setCalibrationFinish] = useState(false);
  const [titleAction, setTitleAction] = useState('Calibrating');

  const [seconds, setSeconds] = useState(15);
  const [progress, setProgress] = useState(100);

  const [recordedChunks, setRecordedChunks] = useState([]);
  const [meshRecordedChunks, setMeshRecordedChunks] = useState([]);

  const [videoUploaded, setVideoUploaded] = useState(false);
  const [videoCover, setVideoCover] = useState('');
  const [meshVideoCover, setMeshVideoCover] = useState('');

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isActiveFacemesh, setIsActiveFacemesh] = useState(false);

  const { updateToken, uploadVideo, uuid, saveSurveyResponse } = useClient();
  const { address, countryCode, setRecordedBPM } = useUser();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const VIDEO_TYPE = MediaRecorder.isTypeSupported('video/mp4') ? 'video/mp4' : 'video/webm';

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected');
    });

    socket.on('disconnect', () => {
      console.log('Disconnected');
    });

    socket.on('response_back', function (image) {
      const object = JSON.parse(image);

      times.current = object.times;
      roiAvgValues.current = object.roiAvgValues;
      lastBpm.current = object.lastBpm;

      setBpm(object.bpm);
      if (object.bpm === 0) {
        setBpm('No face detected');
        setBpmFontSize('16px');
      }
      else if (object.bpm === -1) {
        setBpm('Calibrating...');
        setBpmFontSize('16px');
      }
      else if (object.bpm === -2) {
        setStatus('Finished');
        setSeconds(30);
        setScanerError(<div>There is a problem connecting to the analyzer. Please try these solutions:<ul>
          <li>Use a WiFi connection and move closer to the router</li>
          <li>If you are connected through a VPN, please try turning it off</li>
          <li>Close other tabs that can slowdown the BPM analysis</li>
          </ul>
          </div>);
        setButton({ text: 'Try again', background: '#F53729' });
      }
      else {
        setBpmArray(bpmArray => [...bpmArray, object.bpm]);
        setBpmFontSize('32px');
        setIsCalibrated(true);
        if(!calibrationFinish) {
          setSeconds(30);
          setTitleAction('Countdown for results');
          setCalibrationFinish(true);
        }
      }
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('response_back');
    };
  }, [bpmArray, calibrationFinish]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (status === "Recording" && isCalibrated) {
        const newSeconds = seconds - 1;
        setSeconds(newSeconds);
        setProgress(progress - 3.33);
      }

      if (seconds <= 0 && bpm !== 'Calibrating...') {
        setStatus('Finished');
        setSeconds(30);
        setScanerError('Try focusing better on your face, improving the lighting and improving the quality of the camera.')
        setButton({ text: 'Try again', background: '#F53729' });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [status, seconds, progress, isCalibrated]);

  // Calibration countdown
  useEffect(() => {
    const intervalCalib = setInterval(() => {
      if (bpm === 'Calibrating...') {
        if (seconds <= 0) {
          setSeconds(15)
        } else {
          const newSeconds = seconds - 1;
          setSeconds(newSeconds);
        }
      }
    }, 1000);
    return () => clearInterval(intervalCalib);
  }, [bpm, seconds, progress]);

  // stops the mp4 video recording when the analysis is done
  useEffect(() => {
    if (status === 'Finished') {
      mediaRecorderRef.current.stop();
      meshMediaRecorderRef.current.stop();
    }
  }, [status]);

  // handle the video upload when the recording is done
  useEffect(() => {
    async function upload() {
      if (recordedChunks.length && bpmArray.length >= 150) {
        setIsLoading(true);
        setButton({ text: 'Processing...', background: '#0F77F0' });
        await updateToken();
        let blob = new Blob(recordedChunks, {
          type: VIDEO_TYPE
        }),
        meshBlob = new Blob(meshRecordedChunks, {
          type: VIDEO_TYPE
        }),
        array = new Uint32Array(1),
        file_name = uuid + window.crypto.getRandomValues(array)[0] + '.' + VIDEO_TYPE.replace('video/', '');

        let videoData = {
          address,
          videoCover: videoCover,
          size: (blob.size / 1048576) + 'mb',
          category: 'bpm',
          lang: 'en',
          countryCode: countryCode,
          name: file_name,
          videoFormat: VIDEO_TYPE,
        }


        if( meshBlob.size > 0) {
          const response = await uploadVideo({
            address,
            videoCover: meshVideoCover,
            size: (meshBlob.size / 1048576) + 'mb',
            category: 'mesh',
            lang: 'en',
            countryCode: countryCode,
            name: file_name,
            videoFormat: VIDEO_TYPE
          }, meshBlob);

          videoData.videoRelated = response._id;
        }

        await uploadVideo(videoData, blob);

        setMeshRecordedChunks([]);
        setRecordedChunks([]);
        setVideoUploaded(true);
        sharedVideo();
      } else if (status === 'Finished') {
        setIsLoading(false);
        setBody(<Error message={scannerError} />);
      }
    }

    upload();

  }, [recordedChunks]);

  useEffect(() => {
    if (videoUploaded) {
      let bpmSum = 0;
      bpmArray.forEach(function (a) { bpmSum += a; });;
      let bpmAverage = Math.round(bpmSum / bpmArray.length);
      setRecordedBPM(bpmAverage);
      saveSurveyResponse({
        response: bpmAverage,
        questionId: 'bpm',
        UUID: uuid,
        surveyId: 'bpm'
      });
      disableCamera();
      onClose();
      setVideoRecorderStep();
    }
  }, [videoUploaded, bpmArray, setVideoRecorderStep]);

  function StartRecording() {
    setStatus('Recording');
    setButton({ text: 'Stop', background: '#F53729' });
    setBody(<Recording />);

    if (!isActiveFacemesh) {
      setIsActiveFacemesh(true);

      const faceMesh = new FaceMesh({
        locateFile: (file) => {
          return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
        },
      });

      faceMesh.setOptions({
        maxNumFaces: 1,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5,
      });

      faceMesh.onResults(onResults);

      if (
        typeof webcamRef.current !== "undefined" &&
        webcamRef.current !== null
      ) {
        camera = new cam.Camera(webcamRef.current.video, {
          onFrame: async () => {
            await faceMesh.send({ image: webcamRef.current.video });
          },
          width: 640,
          height: 480,
        });
        camera.start();
      }
    }
  }

  function disableCamera() {
    let stream = webcamRef.current.video.srcObject;
    stream.getTracks().forEach((track) => track.stop());;
    webcamRef.current.video.srcObject = null;
    setIsActiveCamera(false);
  }

  //Set status
  async function UseButton() {
    switch (status) {
      case 'Waiting':
      case 'Starting':
        cameraEnabled();
        setIsOpenModal(true);
        setIsActiveCamera(true);
        let devices
        try {
          devices = await navigator.permissions.query({ name: "camera" });
        } catch (err) {
          console.error('Browser does not support camera permission');
          devices = {
            state: 'prompt'
          };
        }

        if (devices.state === 'prompt' || devices.state === 'granted') {
          roiAvgValues.current = [];
          times.current = [];
          lastBpm.current = 0;
          // the status Waiting will be changed to Recording when we start getting data form the video
          // check the onUserMedia function for more info
          setStatus('Waiting');
        }
        break;
      case 'Recording':
        mediaRecorderRef.current.pause();
        meshMediaRecorderRef.current.pause();
        setStatus('Stopped');
        setButton({ text: 'Resume', background: '#0F77F0' });
        setBody(<Stopped />);
        break;
      case 'Stopped':
        mediaRecorderRef.current.resume();
        meshMediaRecorderRef.current.resume();
        StartRecording();
        break;
      case 'Finished':
        mediaRecorderRef.current.start();
        meshMediaRecorderRef.current.start();
        roiAvgValues.current = [];
        times.current = [];
        lastBpm.current = 0;
        StartRecording();
        setBpmArray([]);
        setProgress(0);
        setRecordedChunks([])
        break;
      default:
        console.error('No status was set')
    }
  }

  async function onUserMediaError(err) {
    blockCamera();
    setStatus('Starting')
    setButton({ text: 'Enable camera and start', background: '#0F77F0' })
    setBody(<Starting />)
  }

  const addRecordedChunk = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const addMeshRecordedChunk = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setMeshRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setMeshRecordedChunks]
  );

  const onUserMedia = React.useCallback(() => {
    if (status === 'Waiting') {
      allowCamera();
      // starts the mp4 video generation
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        videoBitsPerSecond: 1000000,
        mimeType: VIDEO_TYPE
      });
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        addRecordedChunk
      );
      mediaRecorderRef.current.start();

      //Starts the mesh mp4 video generation
      const canvasMeshElement = canvasMeshRef.current;
      canvasMeshElement.getContext('2d');
      meshMediaRecorderRef.current = new MediaRecorder(canvasMeshElement.captureStream(), {
        videoBitsPerSecond: 1000000,
        mimeType: VIDEO_TYPE
      });
      meshMediaRecorderRef.current.addEventListener(
        "dataavailable",
        addMeshRecordedChunk
      );
      meshMediaRecorderRef.current.start();
    
      StartRecording();
    }

    console.log('Webcam active');
  }, [mediaRecorderRef, addRecordedChunk, status, VIDEO_TYPE, uuid]);

  //#region FaceMesh
  function onResults(results) {
    const videoWidth = webcamRef.current.video.videoWidth;
    const videoHeight = webcamRef.current.video.videoHeight;

    //Face without mesh
    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;

    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext("2d");
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    canvasCtx.drawImage(
      results.image,
      0,
      0,
      canvasElement.width,
      canvasElement.height
    );

    //Mesh only
    const canvasMeshElement = canvasMeshRef.current;
    const canvasMeshCtx = canvasMeshElement.getContext("2d");

    canvasMeshCtx.save();
    canvasMeshCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    canvasMeshCtx.fillStyle = "white";
    canvasMeshCtx.fillRect(0, 0, canvasMeshElement.width, canvasMeshElement.height);

    if (results.multiFaceLandmarks) {
      for (const landmarks of results.multiFaceLandmarks) {
        connect(canvasCtx, landmarks, Facemesh.FACEMESH_TESSELATION, {
          color: "#C0C0C070",
          lineWidth: 1,
        });
        connect(canvasCtx, landmarks, Facemesh.FACEMESH_RIGHT_EYE, {
          color: "#E0E0E0",
        });
        connect(canvasCtx, landmarks, Facemesh.FACEMESH_RIGHT_EYEBROW, {
          color: "#E0E0E0",
        });
        connect(canvasCtx, landmarks, Facemesh.FACEMESH_LEFT_EYE, {
          color: "#E0E0E0",
        });
        connect(canvasCtx, landmarks, Facemesh.FACEMESH_LEFT_EYEBROW, {
          color: "#E0E0E0",
        });
        connect(canvasCtx, landmarks, Facemesh.FACEMESH_FACE_OVAL, {
          color: "#E0E0E0",
        });

        connect(canvasMeshCtx, landmarks, Facemesh.FACEMESH_TESSELATION, { color: '#000000', lineWidth: 1 });
        connect(canvasMeshCtx, landmarks, Facemesh.FACEMESH_RIGHT_EYE, { color: '#000000' });
        connect(canvasMeshCtx, landmarks, Facemesh.FACEMESH_RIGHT_EYEBROW, { color: '#000000' });
        connect(canvasMeshCtx, landmarks, Facemesh.FACEMESH_RIGHT_IRIS, { color: '#000000' });
        connect(canvasMeshCtx, landmarks, Facemesh.FACEMESH_LEFT_EYE, { color: '#000000' });
        connect(canvasMeshCtx, landmarks, Facemesh.FACEMESH_LEFT_EYEBROW, { color: '#000000' });
        connect(canvasMeshCtx, landmarks, Facemesh.FACEMESH_LEFT_IRIS, { color: '#000000' });
        connect(canvasMeshCtx, landmarks, Facemesh.FACEMESH_FACE_OVAL, { color: '#000000' });

        let w = canvasCtx["canvas"].width
        let h = canvasCtx["canvas"].height

        let cx_min = w
        let cy_min = h
        let cx_max = 0
        let cy_max = 0

        for (const lm of landmarks) {
          let cx = lm.x * w
          let cy = lm.y * h
          if (cx < cx_min) cx_min = cx
          if (cy < cy_min) cy_min = cy
          if (cx > cx_max) cx_max = cx
          if (cy > cy_max) cy_max = cy

        }

        const canvasFaceElement = canvasFaceRef.current;
        const canvasFaceCtx = canvasFaceElement.getContext("2d");

        canvasFaceCtx.drawImage(webcamRef.current.video,
          cx_min, cy_min,
          cx_max - cx_min, cy_max - cy_min,
          0, 0,
          canvasFaceElement.width, canvasFaceElement.height);

        let image = canvasFaceRef.current.toDataURL(('image/jpg', 0.5));
        let meshImage = canvasMeshRef.current.toDataURL(('image/jpg', 0.5));

        if (!videoCover){ setVideoCover(image); }

        if (!meshVideoCover){ setMeshVideoCover(meshImage); }

        socket.emit('image', {
          image,
          'roiAvgValues': roiAvgValues.current,
          'times': times.current,
          'lastBpm': lastBpm.current
        });
      }
    }
    else {
      setBpm('No face detected');
      setBpmFontSize('16px');
    }

    setImage(canvasRef.current.toDataURL('image/jpeg', 1));

    canvasCtx.restore();
  }
  //#endregion

  function CloseModal() {
    setIsOpenModal(false);
  }

  return (
    <Box width="100%" height={{ base: '100%', md: 'auto' }}>
      <BaseModal isOpenModal={isOpenModal} handleClick={CloseModal}>
        <Image
          src='/img/ModalIcon.png'
          alt='modal-icon'
          width={{ base: "110px", md: "150px" }}
          margin='15px auto'
        />
        <Text
          fontWeight="400"
          fontSize={{ base: "19px", md: "26px" }}
          lineHeight={{ base: "20px", md: "27px" }}
          textAlign="center"
        >
          For a better experience it is recommended to have a good camera, lighting and have a clear face.
        </Text>
      </BaseModal>
      <Grid
        templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(10, 1fr)' }}
        gap={4}
      >
        <GridItem colSpan={{ sm: 1, md: 3 }} p="5px" display="flex" flexDirection="column" justifyContent="space-between">
          {(status != "Recording" || window.innerWidth > 768) &&
            <Grid gridTemplateColumns="repeat(4, 1fr)" width="100%" padding='0 20px' gap={6}>
              <GridItem colSpan={{ base: status === 'Starting' ? 4 : 1, md: 4 }} >
                <Image
                  src='/logo.png'
                  alt='logo-image'
                  w="80px"
                  margin={{ base: "10px auto 10px auto", md: "0 0 30px 0" }}
                />
              </GridItem>
              <GridItem colSpan={{ base: status === 'Starting' ? 4 : 3, md: 4 }} display="flex" flexDirection="column" justifyContent="center">
                <Text
                  fontStyle='normal'
                  fontWeight='bold'
                  fontSize='25px'
                  textAlign={{ base: status === 'Starting' ? "center" : 'left', md: "left" }}
                >
                  Heart Rate AI
                </Text>
              </GridItem>
            </Grid>
          }
          {(status !== "Stopped" && status !== "Finished" || window.innerWidth > 768) &&
            <Box padding={{ base: "0 20px", md: "0" }}>
              {body}
            </Box>
          }
          <Button
            bg={button.background}
            width="100%"
            height="81px"
            borderRadius='13px' color='#ffffff'
            fontStyle='normal'
            fontWeight='600'
            fontSize='26.17px'
            lineHeight='31px'
            textAlign='center'
            display={{ base: 'none', sm: 'none', md: 'block' }}
            onClick={UseButton}
            _hover={{ background: button.background }}
            _focus={{ background: button.background }}
          >
            <ProgressBar isLoading={isLoading} />
            {button.text}
          </Button>

        </GridItem>
        <GridItem colSpan={{ sm: 1, md: 7 }} p="0">
          <Box position="relative" display="inline-block" width="100%">
            {status === "Recording" &&
              <Box position="absolute" top="10px" left="10px" display={{ base: 'none', sm: 'none', md: 'block' }}>
                <Text
                  fontStyle='normal'
                  fontWeight='bold'
                  fontSize='20px'
                  color='#FFFFFF'
                  textShadow='#5D5D5D 1px 0 10px'
                >Reading
                </Text>
                <Image src='/img/Heart.png' display="inline-block" marginRight="10px" />
                <Text
                  display="inline-block"
                  fontStyle='normal'
                  fontWeight='bold'
                  fontSize='40px'
                  color='#FFFFFF'
                  textShadow='#5D5D5D 1px 0 10px'
                >{bpm}
                </Text>
                {bpm > 0 &&
                  <Text fontSize="41px" fontWeight="400" color="#F53729" display="inline-block">
                    BPM
                  </Text>
                }
              </Box>
            }
            {status === "Recording" &&
              <Box height="130px" position="absolute" bottom="10px" left="10px" background="#FFFFFF" borderRadius="15px" display={{ base: 'none', sm: 'none', md: 'block' }}>
                <Grid
                  templateAreas={`"progress countdown"`}
                  gridTemplateColumns={'100px 230px'}
                  gap='0'
                  height="100%"
                >
                  <GridItem area={'progress'} display="flex" flexDirection="column" justifyContent="center">
                    <Center><CircularProgress value={progress} /></Center>
                  </GridItem>
                  <GridItem area={'countdown'} display="flex" flexDirection="column" justifyContent="center">
                    <Text
                      fontWeight='400'
                      fontSize='16px'
                      color='#A9B2CF'
                    >{titleAction}
                    </Text>
                    <Text
                      fontWeight='700'
                      fontSize='42px'
                      color='#1D2556'
                    >00:{seconds < 10 && "0"}{seconds}
                    </Text>
                  </GridItem>
                </Grid>
              </Box>
            }
            {(status !== "Starting" && status !== "Waiting") &&
              <canvas
                hidden
                ref={canvasRef}
                className="output_canvas"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center",
                  zindex: 1,
                  width: '1280px',
                  height: '720px'
                }}
              ></canvas>
            }
            {isActiveCamera &&
              <Webcam audio={false} width="0" height="0" style={{}} screenshotFormat="image/jpeg" mirrored={true} ref={webcamRef} onUserMediaError={onUserMediaError} onUserMedia={onUserMedia} />
            }
            {(status !== "Starting" && status !== "Waiting") &&
              <canvas
                hidden
                ref={canvasFaceRef}
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  left: 0,
                  right: 0,
                  textAlign: "center",
                  zindex: 1,
                  width: 320,
                  height: 240
                }}
              ></canvas>
            }
            <canvas hidden ref={canvasMeshRef} width="1280px" height="720px"></canvas>
            <Image src={image} width="100%" borderRadius={{ base: '0', md: "10px" }} />
          </Box>
          {status === "Recording" &&
            <Grid templateColumns='repeat(2, 1fr)' gap={0} padding="0 20px" margin="10px 0" display={{ base: 'grid', sm: 'grid', md: 'none' }}>
              <GridItem w='100%' h='73px' borderRight="1px solid #909AA1">
                <Text
                  fontStyle='normal'
                  fontWeight='bold'
                  fontSize='12px'
                  color='#000000'
                >Reading
                </Text>
                <Image src='/img/Heart.png' width="10px" display="inline-block" marginRight="10px" />
                <Text
                  display="inline-block"
                  fontStyle='normal'
                  fontWeight='bold'
                  fontSize={bpmFontSize}
                  color='#000000'
                >{bpm}
                </Text>
                {bpm > 0 &&
                  <Text fontSize="23px" fontWeight="400" color="#F53729" display="inline-block">
                    BPM
                  </Text>
                }
              </GridItem>
              <GridItem w='100%' h='73px'>
                <Grid
                  templateAreas={`"progress countdown"`}
                  gridTemplateColumns={'40% 60%'}
                  gap='0'
                  height="100%"
                >
                  <GridItem area={'progress'} display="flex" flexDirection="column" justifyContent="center">
                    <Center><CircularProgress value={progress} /></Center>
                  </GridItem>
                  <GridItem area={'countdown'} display="flex" flexDirection="column" justifyContent="center">
                    <Text
                      fontWeight='400'
                      fontSize='9px'
                      color='#A9B2CF'
                    >{titleAction}
                    </Text>
                    <Text
                      fontWeight='700'
                      fontSize='23px'
                      color='#1D2556'
                    >00:{seconds < 10 && "0"}{seconds}
                    </Text>
                  </GridItem>
                </Grid>
              </GridItem>
            </Grid>
          }
          {(status === "Stopped" || status === "Finished") && window.innerWidth < 768 && body}
          <Box padding="0 20px">
            <Button
              bg={button.background}
              width="100%"
              height="81px"
              borderRadius='13px' color='#ffffff'
              fontStyle='normal'
              fontWeight='600'
              fontSize='26.17px'
              lineHeight='31px'
              textAlign='center'
              margin="20px 0"
              display={{ base: 'block', sm: 'block', md: 'none' }}
              onClick={UseButton}
              _hover={{ background: button.background }}
              _focus={{ background: button.background }}
            >
              <ProgressBar isLoading={isLoading} />
              {button.text}
            </Button>
          </Box>
        </GridItem>
      </Grid>
      <Modal isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent width="100px" position="absolute" top="50%" marginTop="-100px">
          <Center>
            <CircularProgress isIndeterminate size='100px' thickness='8px'></CircularProgress>
          </Center>
        </ModalContent>
      </Modal>
    </Box>

  )
}

export default VideoRecorder;
import { Box, Text, Image } from '@chakra-ui/react';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid'; 

import useClient from '../../../hooks/useClient';

const Welcome = () => {
    const { setUuid } = useClient();
    const content = {
        body: 'Hi there, nice to see you here!',
    }

    useEffect(() => {
        const uuid = uuidv4();
        setUuid(uuid);
    }, []);

    return (
        <Box height={{base: "100%", md: "100%"}}>
            <Image
                src='/waving_hand.png'
                alt='logo-image'
                margin="250px auto 60px auto"
            />

            <Text
                fontStyle='normal'
                fontWeight='600'
                fontSize='40px'
                textAlign='center'
                margin="0px auto"
            >
                {content.body}
            </Text>
        </Box>

    )
}

export default Welcome;
import axios from 'axios';
import { createContext, useState } from 'react';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const AxiosContext = createContext();

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-auth-token': '',
            'Authorization': 'Basic ' + process.env.REACT_APP_BASIC_AUTH,
            'project-id': 'bpm-analyzer'
    },
    timeout: 10000
});

const AxiosProvider = ({children}) => {
    const [uid, setUid] = useState('');
    const [address, setAddress] = useState('');
    const [uuid, setUuid] = useState('');

    const updateToken = async () => {
        try {
            const response = await firebase.auth().signInAnonymously()
            const token = await response.user.getIdToken();

            apiClient.defaults.headers['x-auth-token'] = `Bearer ${token}`;
            
            setUid(response.user.multiFactor.user.uid)
        } catch (error) {
            console.log(error)
        }
    }

    const getUserLocation = () => {
        return apiClient.get('/shoppingcart/getUserLocation');
    }
    
    const saveDevice = (device) => {
        device.device = uid;

        return apiClient.post('/addDevice', device);
    }

    const saveProfile = (profile) => {
        setAddress(profile.address);
        return apiClient.post('/metadata/profile', profile);
    }

    const updateAddress = (data) => {
        setAddress(data.value);
        return apiClient.patch('/metadata/profile', data);
    }

    const saveSurveyResponse = (data) => {
        data.UUID = uuid;
        return apiClient.post('/survey-response', data)
    }

    const updateProfile = (data) => {
        return apiClient.patch(`/metadata/profile`, data);
    }

    const uploadVideo = async (videoMetadata, videoBlob) => {
        const videoReference = await apiClient.post('/video/add', videoMetadata);

        const chunkSize = 5 * 1024 * 1024;
        const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
        const blockCount = Math.ceil(videoBlob.size / chunkSize);
        let multipartUpload = {
            Parts: []
        }

        for (let i = 1; i <= blockCount; i++) {
            const start = (i-1) * chunkSize;
            const end = Math.min(videoBlob.size, start + chunkSize);

            const data = new FormData();
            data.append('uploadFinish', false);
            data.append('chunkNumber', i);
            data.append('identifier', videoReference.data.videoId);
            data.append('file', blobSlice.call(videoBlob, start, end));
            
            const partResponse = await apiClient.post('/video/upload',
            data,
            {
                headers: { ...apiClient.defaults.headers, 'Content-Type': 'multipart/form-data' }
            }
            );

            multipartUpload.Parts.push(partResponse.data);
        }

        apiClient.post('/video/upload', {
          uploadFinish: true,
          identifier: videoReference.data.videoId,
          multipartUpload: multipartUpload,
        });
        
        return videoReference.data;
    }

    return (
        <AxiosContext.Provider
            value={{
                updateToken,
                saveDevice,
                getUserLocation,
                saveProfile,
                saveSurveyResponse,
                updateProfile,
                uuid,
                setUuid,
                uploadVideo,
                updateAddress
            }}
        >
            {children}
        </AxiosContext.Provider>
    );
};

export {AxiosProvider};
export default AxiosContext;
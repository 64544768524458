import React, { useState } from "react";

import {
    Box,
    Image,
    Text,
    Button,
    Grid,
    GridItem,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb
} from '@chakra-ui/react';

import { answeredHealthStateSurvey } from "../../../utils/facebookPixelEvents";

import useClient from '../../../hooks/useClient';

const HealthState = ({ setSection }) => {
    const { uuid, saveSurveyResponse } = useClient();
    const [mobility, setMovility] = useState(1);
    const [usualActivities, setUsualActivities] = useState(1);
    const [painDiscomfort, setPainDiscomfort] = useState(1);
    const [anxietyDiscomfort, setAnxietyDiscomfort] = useState(1);

    const content = {
        body: 'Please enter health state description',
        secondaryText: "Slide it!",
        button: 'Submit'
    }

    function SubmitSurvey() {
        try {
            answeredHealthStateSurvey();

            saveSurveyResponse({ response: mobility, questionId: 'mobility', UUID: uuid, surveyId: 'bpm' });
            saveSurveyResponse({ response: usualActivities, questionId: 'usual_activities', UUID: uuid, surveyId: 'bpm' });
            saveSurveyResponse({ response: painDiscomfort, questionId: 'pain_discomfort', UUID: uuid, surveyId: 'bpm' });
            saveSurveyResponse({ response: anxietyDiscomfort, questionId: 'anciety_discomfort', UUID: uuid, surveyId: 'bpm' });
        } catch (error) {
            console.log('Something wrong happend');
            console.error(error);
        }

        setSection();
    }

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" height={{ base: "92%", md: "90%" }}>
            <Image
                src='/logo.png'
                alt='logo-image'
                margin="30px auto"
                width={{ md: "auto", base: "80px" }}
            />

            <Text
                fontStyle='normal'
                fontWeight='bold'
                fontSize={{ base: "30px", md: '40px' }}
                textAlign='center'
            >
                {content.body}
            </Text>

            <Text
                fontStyle='normal'
                fontWeight='400'
                fontSize='20px'
                textAlign='center'
                color='#C2C2C2'
            >
                {content.secondaryText}
            </Text>

            {/* MOVILITY SLIDER */}
            <Grid
                templateAreas={`"description slider value"`}
                gridTemplateColumns={'30% 60% 10%'}
                gap='0'
                fontWeight='bold'
            >
                <GridItem area={'description'}>
                    Mobility
                </GridItem>
                <GridItem area={'slider'}>
                    <Slider onChange={e => setMovility(e)} aria-label='slider-ex-1' defaultValue={1} min={0} max={5} step={1}>
                        <SliderTrack h="21px" borderRadius="20px">
                            <SliderFilledTrack bg="#63E17E" />
                        </SliderTrack>
                        <SliderThumb boxSize={6} p="2px" bg="#63E17E">
                            <Box bg="#FFFFFF" w="100%" height="100%" borderRadius="100%" />
                        </ SliderThumb>
                    </Slider>
                </GridItem>
                <GridItem area={'value'}>
                    <Text
                        fontStyle='normal'
                        fontWeight='bold'
                        textAlign='center'
                    >
                        {mobility}
                    </Text>
                </GridItem>
            </Grid>

            {/* USUAL ACTIVITIES SLIDER */}
            <Grid
                templateAreas={`"description slider value"`}
                gridTemplateColumns={'30% 60% 10%'}
                gap='0'
                fontWeight='bold'
            >
                <GridItem area={'description'}>
                    Usual activities
                </GridItem>
                <GridItem area={'slider'}>
                    <Slider onChange={e => setUsualActivities(e)} aria-label='slider-ex-1' defaultValue={1} min={0} max={5} step={1}>
                        <SliderTrack h="21px" borderRadius="20px">
                            <SliderFilledTrack bg="#63E17E" />
                        </SliderTrack>
                        <SliderThumb boxSize={6} p="2px" bg="#63E17E">
                            <Box bg="#FFFFFF" w="100%" height="100%" borderRadius="100%" />
                        </ SliderThumb>
                    </Slider>
                </GridItem>
                <GridItem area={'value'}>
                    <Text
                        fontStyle='normal'
                        fontWeight='bold'
                        textAlign='center'
                    >
                        {usualActivities}
                    </Text>
                </GridItem>
            </Grid>

            {/* PAIN/DISCONFORT SLIDER */}
            <Grid
                templateAreas={`"description slider value"`}
                gridTemplateColumns={'30% 60% 10%'}
                gap='0'
                fontWeight='bold'
            >
                <GridItem area={'description'}>
                    Pain/<br />Discomfort
                </GridItem>
                <GridItem area={'slider'}>
                    <Slider onChange={e => setPainDiscomfort(e)} aria-label='slider-ex-1' defaultValue={1} min={0} max={5} step={1}>
                        <SliderTrack h="21px" borderRadius="20px">
                            <SliderFilledTrack bg="#63E17E" />
                        </SliderTrack>
                        <SliderThumb boxSize={6} p="2px" bg="#63E17E">
                            <Box bg="#FFFFFF" w="100%" height="100%" borderRadius="100%" />
                        </ SliderThumb>
                    </Slider>
                </GridItem>
                <GridItem area={'value'}>
                    <Text
                        fontStyle='normal'
                        fontWeight='bold'
                        textAlign='center'
                    >
                        {painDiscomfort}
                    </Text>
                </GridItem>
            </Grid>

            {/* ANXIETY/DISCOMFORT SLIDER */}
            <Grid
                templateAreas={`"description slider value"`}
                gridTemplateColumns={'30% 60% 10%'}
                gap='0'
                fontWeight='bold'
            >
                <GridItem area={'description'}>
                    Anxiety/<br />Discomfort
                </GridItem>
                <GridItem area={'slider'}>
                    <Slider onChange={e => setAnxietyDiscomfort(e)} aria-label='slider-ex-1' defaultValue={1} min={0} max={5} step={1}>
                        <SliderTrack h="21px" borderRadius="20px">
                            <SliderFilledTrack bg="#63E17E" />
                        </SliderTrack>
                        <SliderThumb boxSize={6} p="2px" bg="#63E17E">
                            <Box bg="#FFFFFF" w="100%" height="100%" borderRadius="100%" />
                        </ SliderThumb>
                    </Slider>
                </GridItem>
                <GridItem area={'value'}>
                    <Text
                        fontStyle='normal'
                        fontWeight='bold'
                        textAlign='center'
                    >
                        {anxietyDiscomfort}
                    </Text>
                </GridItem>
            </Grid>



            <Button bg="#0F77F0" width="100%" height="81px" borderRadius='13px' color='#ffffff'
                fontStyle='normal'
                fontWeight='600'
                fontSize='26.17px'
                lineHeight='31px'
                textAlign='center'
                isDisabled={mobility === 0 || usualActivities === 0 || painDiscomfort === 0 || anxietyDiscomfort === 0}
                onClick={SubmitSurvey}
            >
                {content.button}
            </Button>
        </Box>

    )
}

export default HealthState;
import { Box } from '@chakra-ui/react';

import React, { useState } from 'react';

import HaveAddress from './add-address-body/HaveAddress';
import AddressForm from './add-address-body/AddressForm';
import Success from './add-address-body/Success';

const AddExistingAddress = ({ setVideoRecorderStep, isChangingAddress }) => {
    const setSuccess = (e) => {setBody(<Success setVideoRecorderStep={setVideoRecorderStep} />)};
    const setAddressForm = () => setBody(<AddressForm setSuccess={setSuccess} setVideoRecorderStep={setVideoRecorderStep} />);

    const [body, setBody] = useState(isChangingAddress ? <AddressForm setSuccess={setSuccess} setVideoRecorderStep={setVideoRecorderStep} /> : <HaveAddress setAddressForm={setAddressForm} setVideoRecorderStep={setVideoRecorderStep} />);

    return (
        <Box width="100%" padding={{base: "10px", md: "0 30px"}}>
            {body}
        </Box>

    )
}

export default AddExistingAddress;
import { Box, Button, Text, Image, Link } from '@chakra-ui/react';
import { useState } from 'react';

import { aceptTermsOfUse, viewTermsOfUse } from '../../../utils/facebookPixelEvents';

const PrivacyPolicy = ({ setStep }) => {
    const [privacyPolicy] = useState(process.env.REACT_APP_PRIVACY_POLICY);
    const [termsOfUse] = useState(process.env.REACT_APP_TERMS_USE);

    const content = {
      body: 'By clicking Continue below you agree to our Terms of Use and Privacy Policy.',
      button: 'Continue'
    }
  
    function AceptTermsOfUse() {
      aceptTermsOfUse();
      setStep();
    }
  
    return (
      <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
        <Image
          src='/logo.png'
          alt='logo-image'
          margin="30px auto"
          width={{md: "auto", base: "80px"}}
        />
  
        <Text
          fontStyle='normal'
          fontWeight='400'
          fontSize='20px'
          textAlign='center'
        >
          {content.body}
        </Text>

        <Text color='#0F77F0' textAlign='center' fontSize='23px' as='u'>
          <Link color='#0F77F0' isExternal href={termsOfUse} onClick={viewTermsOfUse}>Terms of Use</Link> and <Link color='#0F77F0' isExternal href={privacyPolicy} onClick={viewTermsOfUse}>Privacy Policy.</Link>
        </Text>
  
        <Button bg="#0F77F0" width="100%" height="81px" borderRadius='13px' color='#ffffff'
          fontStyle='normal'
          fontWeight='600'
          fontSize='26.17px'
          lineHeight='31px'
          textAlign='center'
          onClick={AceptTermsOfUse}
        >
          {content.button}
        </Button>
      </Box>
  
    )
  }

  export default PrivacyPolicy;
import {
    Box,
    Image,
    Text,
    Button,
    NumberInput,
    NumberInputField,
    Select,
    Grid,
    GridItem
} from '@chakra-ui/react'

import { useState } from 'react';

import useUser from '../../../hooks/useUser';
import useClient from '../../../hooks/useClient';

const WhatIsYourWeight = ({ setStep }) => {
    const { uuid } = useClient();
    const { weight, setWeight, weightMetric, setWeightMetric } = useUser();
    const [isInvalidWeight, setIsInvalidWeight] = useState(false);
    const [isInvalidWeightMetric, setIsInvalidWeightMetric] = useState(false);

    const content = {
        body: 'What is your weight?',
        secondaryText: "Please enter your age below. We don't ask your date of birth to protect your privacy.",
        button: 'Continue'
    }

    function ChangeStep() {
        if (weight == null || weight < 1) {
            setIsInvalidWeight(true);
            return
        } else {
            setIsInvalidWeight(false);
        }

        if (weightMetric === null || weightMetric === '--') {
            setIsInvalidWeightMetric(true);
            return
        } else {
            setIsInvalidWeightMetric(false)
        }

        setStep();
    }

    function ChangeMetric(value) {
        setWeightMetric(value);
        if(weightMetric == "kg"){
            setWeight((weight * 2.2).toFixed(2));
        }
        else {
            setWeight((weight / 2.2).toFixed(2));
        }
    }

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" height={{base: "92%", md: "90%"}}>
            <Image
                src='/logo.png'
                alt='logo-image'
                margin="30px auto"
                width={{ md: "auto", base: "80px" }}
            />

            <Text
                fontStyle='normal'
                fontWeight='bold'
                fontSize={{ base: "30px", md: '40px' }}
                textAlign='center'
            >
                {content.body}
            </Text>

            <Grid
                templateAreas={`"input select"`}
                gridTemplateColumns={'75% 25%'}
                gap='0'
                fontWeight='bold'
            >
                <GridItem paddingRight="4px" area={'input'}>
                    <NumberInput isInvalid={isInvalidWeight} max={595} value={weight}>
                        <NumberInputField placeholder='Type here e.g. 100'
                            border="3px solid"
                            height="80px"
                            borderRadius="13px"
                            fontSize={{base: '20px', md: '26px'}}
                            fontWeight='500'
                            onChange={e => setWeight(e.currentTarget.value)}
                        />
                    </NumberInput>
                </GridItem>
                <GridItem area={'select'}>
                    <Select
                        height="80px"
                        bg={{ base: isInvalidWeightMetric ? "red" : "#0F77F0" }}
                        color="white"
                        borderRadius="13px"
                        fontSize={{base: '18px', md: '26px'}}
                        fontWeight='500'
                        _hover={{
                            background: "#0F77F0",
                            cursor: "pointer"
                        }}
                        onChange={e => ChangeMetric(e.target.value)}
                        defaultValue={weightMetric}
                    >
                        <option value='kg' style={{color: 'black'}}>KG</option>
                        <option value='lb' style={{color: 'black'}}>LBS</option>
                    </Select>
                </GridItem>
            </Grid>




            <Button bg="#0F77F0" width="100%" height="81px" borderRadius='13px' color='#ffffff'
                fontStyle='normal'
                fontWeight='600'
                fontSize='26.17px'
                lineHeight='31px'
                textAlign='center'
                onClick={ChangeStep}
            >
                {content.button}
            </Button>
        </Box>

    )
}

export default WhatIsYourWeight;
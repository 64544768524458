import { Box, Text, Image, Button } from '@chakra-ui/react';

const Success = ({setVideoRecorderStep}) => {
    return (
        <Box>
            <Image
                src='/logo.png'
                alt='logo-image'
                w={{base: '60px', md: "80px"}}
                margin="30px auto"
            />
            <Text
                fontWeight="600"
                fontSize={{base: '22px', md: "34px"}}
                lineHeight={{base: '26px', md: "48px"}}
                textAlign="center"
                marginBottom="35px"
            >
                Congratulations, your wallet address has been received!
            </Text>
            <Button
                bg="#0F77F0"
                width="100%"
                height={{base: '47px', md: "88px"}}
                borderRadius={{base: '7px', md: '11px'}}
                color='#FFFFFF'
                fontWeight='600'
                fontSize={{base: '17px', md: '26px'}}
                textAlign='center'
                onClick={setVideoRecorderStep}
            >
                Continue
            </Button>
        </Box>
    )
}

export default Success;
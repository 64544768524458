import {
    Box,
    Image,
    Text,
    Button,
    Input,
    Grid,
    GridItem,
    useCheckbox,
    useCheckboxGroup,
    Flex,
    chakra,
    useDisclosure,
} from '@chakra-ui/react'

import React, { useState } from 'react';

import useClient from '../../../hooks/useClient';
import useUser from '../../../hooks/useUser';

import ChangeAddressCollapse from '../../../components/ChangeAddressCollapse';
import { ahtDepositOptIn, dataOptOut } from '../../../utils/facebookPixelEvents';

const EmailSubmit = ({ setVideoRecorderStep, changeAddress }) => {
    const [email, setEmail] = useState('');
    const [isInvalidEmail, setIsInvalidEmail] = useState(false);
    const [submitedEmail, setSubmitedEmail] = useState(false);
    const { updateProfile, saveSurveyResponse, uuid, } = useClient();
    const { address, hasAddress } = useUser();
    const [dataToContribute, setDataToContribute] = useState([])
    const { isOpen, onToggle } = useDisclosure();

    const shortAddres = address.substring(0, 5) + "..." + address.substring(address.length - 4, address.length);

    function ChangeStep() {
        setVideoRecorderStep();
    }

    function SubmitEmail() {
        if (email === null || email === '') {
            setIsInvalidEmail(true)
        } else {
            ahtDepositOptIn();
            setIsInvalidEmail(false)
            setSubmitedEmail(true);
            updateEmail();
            saveContributeData();

            setTimeout(() => {
                setVideoRecorderStep();
            }, 4000);
        }
    }

    async function saveContributeData() {
        try {
            if (dataToContribute.length > 0) {
                const data = {
                    'response': dataToContribute,
                    'questionId': 'like_to_contribute_in_future',
                    'UUID': '',
                    'surveyId': 'bpm'
                };

                await saveSurveyResponse(data);
            }
        } catch (error) {
            console.log('Something wrong happend');
            console.log(error);
        }
    }

    async function updateEmail() {
        const data = [
            {
                "op": "add",
                "path": "/email",
                "value": email
            }
        ]

        await updateProfile(data);
    }

    const CustomCheckbox = (props) => {
        const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } =
            useCheckbox(props)

        return (
            <chakra.label
                display='flex'
                flexDirection='row'
                alignItems='center'
                border={{ base: state.isChecked ? '2px solid #0F77F0' : '2px solid #CFD2D9' }}
                borderRadius="9px"
                cursor='pointer'
                padding="15px"
                {...htmlProps}
            >
                <input {...getInputProps()} hidden />
                <Flex
                    alignItems='center'
                    justifyContent='center'
                    bg='#CFD2D9'
                    borderRadius="100%"
                    w={{ base: '9px', md: "18px" }}
                    h={{ base: '9px', md: "18px" }}
                    {...getCheckboxProps()}
                >
                    {state.isChecked && <Box w={{ base: '9px', md: "18px" }} h={{ base: '9px', md: "18px" }} bg='#0F77F0' borderRadius="100%" />}
                </Flex>
                <Text fontSize={{ base: '10px', sm: '13px', md: "25px" }} ml={{ base: '5px', md: "10px" }} {...getLabelProps()}>{props.text}</Text>
            </chakra.label>
        )
    }
    const { value, getCheckboxProps } = useCheckboxGroup({
        defaultValue: dataToContribute,
        onChange: setDataToContribute,
    })

    return (
        <Box width="100%">

            <Grid
                templateAreas={{ base: `"words"`, md: `"words result"` }}
                gridTemplateColumns={{ base: '100%', md: '45% 55%' }}
                gap='0'
            >
                <GridItem area={'words'} padding="0 20px" display="flex" flexDirection="column" justifyContent="space-between">
                    {hasAddress &&
                        <ChangeAddressCollapse changeAddress={changeAddress} shortAddres={shortAddres} isMobile={false} />
                    }
                    <Image
                        src='/logo.png'
                        alt='logo-image'
                        w="80px"
                        mb="30px"
                        display={{ base: 'none', md: 'block' }}
                    />
                    <Image
                        src='/img/EmailImage.png'
                        alt='logo-image'
                        w="100%"
                        mb="10px"
                        display={{ base: 'block', md: 'none' }}
                    />
                    <Text
                        fontWeight='bold'
                        fontSize='32px'
                        textAlign={{ base: "center", md: 'left' }}
                    >Thank you!</Text><br />
                    <Text
                        color="#27AC43"
                        fontSize={{ base: "13px", md: "25px" }}
                        lineHeight={{ base: "15px", md: "30px" }}
                        fontWeight="400"
                        background="rgba(39, 172, 67, 0.17);"
                        borderRadius={{ base: "11px", md: "16px" }}
                        padding={{ base: "12px", md: "15px" }}
                    >
                        We are reviewing your submission. This may take us up to 3 business days.
                    </Text><br />
                    <Text
                        fontSize={{ base: '14px', md: '25px' }}
                        textAlign={{ base: "center", md: 'left' }}
                    >If you would like to be notified about the AHT deposit please enter your email below and to receive future opportunities to contribute your health data:</Text>
                    {hasAddress &&
                        <ChangeAddressCollapse changeAddress={changeAddress} shortAddres={shortAddres} isMobile={true} />
                    }
                    <br />

                    <Box display={{ base: 'block', md: 'none' }} background="#EBFAFF" borderRadius="17px" padding="15px" marginBottom="20px">
                        <Text
                            fontWeight='bold'
                            fontSize={{ base: '14px', md: '28px' }}
                            color="#0F77F0"
                        >Please select what type of data you want to contribute for future occasions</Text>
                        <Grid templateColumns='repeat(2, 1fr)' gap={2} marginTop="6px">
                            <GridItem w='100%'>
                                <CustomCheckbox text="Cardiovascular" {...getCheckboxProps({ value: 'Cardiovascular' })} />
                            </GridItem>
                            <GridItem w='100%'>
                                <CustomCheckbox text="General Wellness" {...getCheckboxProps({ value: 'General Wellness' })} />
                            </GridItem>
                            <GridItem w='100%'>
                                <CustomCheckbox text="Dermatology" {...getCheckboxProps({ value: 'Dermatology' })} />
                            </GridItem>
                            <GridItem w='100%'>
                                <CustomCheckbox text="Mental Health" {...getCheckboxProps({ value: 'Mental Health' })} />
                            </GridItem>
                            <GridItem w='100%'>
                                <CustomCheckbox text="Oncology" {...getCheckboxProps({ value: 'Oncology' })} />
                            </GridItem>
                            <GridItem w='100%'>
                                <CustomCheckbox text="Lung Health" {...getCheckboxProps({ value: 'Lung Health' })} />
                            </GridItem>
                        </Grid>
                    </Box>
                    {!submitedEmail &&
                        <Box>
                            <Input isInvalid={isInvalidEmail}
                                margin="10px 0"
                                placeholder='Email address'
                                height={{ base: "55px", md: "73px" }}
                                borderRadius={{ base: "7px", md: "13px" }}
                                onChange={e => setEmail(e.currentTarget.value)} />
                            <br />
                            <Button margin="10px 0"
                                width="100%"
                                height={{ base: "55px", md: "73px" }}
                                background="#0F77F0"
                                color="#FFFFFF"
                                borderRadius={{ base: "7px", md: "13px" }}
                                fontSize={{ base: "19px", md: "23px" }}
                                onClick={SubmitEmail}>
                                Notify me!
                            </Button>
                            <Button onClick={() => { dataOptOut(); ChangeStep();}} background="#FFFFFF" color="#0F77F0" fontSize={{ base: "16px", md: "23px" }} textAlign="left" fontWeight="400">I don't want to contribute data</Button>
                        </Box>
                    }
                    {submitedEmail &&
                        <Text
                            color="#0F77F0"
                            fontSize={{ base: "15px", md: "22px" }}
                            fontWeight="400"
                            background="rgba(15, 119, 240, 0.17);"
                            borderRadius={{ base: "6px", md: "9px" }}
                            padding={{ base: "15px", md: "20px" }}
                        >
                            Great, you registered your email. Soon you’ll receive notifications from us
                        </Text>
                    }
                </GridItem>
                <GridItem area={'result'} padding="0 20px" display={{ base: 'none', md: 'block' }}>
                    <Box border="1px #0F77F0 solid" height="100%" borderRadius="21px" padding="15px">
                        <Image
                            src='/img/EmailImage.png'
                            alt='logo-image'
                            w="100%"
                            mb="10px"
                        />
                        <Text
                            fontWeight='bold'
                            fontSize='28px'
                            color="#0F77F0"
                        >Please select what type of data you want to contribute for future occasions</Text>
                        <br />
                        <Grid templateColumns={{ md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }} gap={6}>
                            <GridItem w='100%'>
                                <CustomCheckbox text="Cardiovascular" {...getCheckboxProps({ value: 'Cardiovascular' })} />
                            </GridItem>
                            <GridItem w='100%'>
                                <CustomCheckbox text="General Wellness" {...getCheckboxProps({ value: 'General Wellness' })} />
                            </GridItem>
                            <GridItem w='100%'>
                                <CustomCheckbox text="Dermatology" {...getCheckboxProps({ value: 'Dermatology' })} />
                            </GridItem>
                            <GridItem w='100%'>
                                <CustomCheckbox text="Mental Health" {...getCheckboxProps({ value: 'Mental Health' })} />
                            </GridItem>
                            <GridItem w='100%'>
                                <CustomCheckbox text="Oncology" {...getCheckboxProps({ value: 'Oncology' })} />
                            </GridItem>
                            <GridItem w='100%'>
                                <CustomCheckbox text="Lung Health" {...getCheckboxProps({ value: 'Lung Health' })} />
                            </GridItem>
                        </Grid>
                    </Box>
                </GridItem>
            </Grid>
        </Box>

    )
}

export default EmailSubmit;
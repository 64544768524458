import {
    Box,
    Image,
    Text,
    Button,
    NumberInput,
    NumberInputField,
    Select,
    Grid,
    GridItem
} from '@chakra-ui/react'

import { useState, useEffect } from 'react';

import useUser from '../../../hooks/useUser';
import useClient from '../../../hooks/useClient';

const WhatIsYourHeight = ({ setStep }) => {
    const { uuid } = useClient();
    const { height, heightMetric, setHeight, setHeightMetric } = useUser();
    const [isInvalidHeight, setIsInvalidHeight] = useState(false);
    const [isInvalidHeightMetric, setIsInvalidHeightMetric] = useState(false);
    const [inch, setInch] = useState(0);

    const content = {
        body: 'What is your height?',
        secondaryText: "Please enter your age below. We don't ask your date of birth to protect your privacy.",
        button: 'Continue'
    }

    useEffect(() => {
        if (heightMetric == 'ft') {
            let rawValue = height;

            let inches = 12 * (rawValue % 1.0);
            setInch(Math.round(inches));
            setHeight(Math.trunc(rawValue));
        }
    }, []);

    function ChangeStep() {
        if (height == null || height < 1) {
            setIsInvalidHeight(true);
            return
        } else if(heightMetric == 'ft' && inch <= 0) {
            setIsInvalidHeight(true);
            return
        } else {
            setIsInvalidHeight(false);
        }

        if (heightMetric === null || heightMetric === '--') {
            setIsInvalidHeightMetric(true);
            return
        } else {
            setIsInvalidHeightMetric(false);
        }

        if (heightMetric === 'ft' && inch > 0) {
            setHeight(Number(height) + Number(inch / 12));
        }

        setStep();
    }

    function ChangeMetric(metric) {
        setHeightMetric(metric);

        let rawValue = height;
        rawValue *= metric === "cm" ? 30.48 : 1 / 30.48;

        if (metric == "cm") {
            setHeight(Math.round(height * 30.48 + inch * 2.54));
            setInch(0);
        }
        else if (metric == "ft") {
            let inches = 12 * (rawValue % 1.0);
            setInch(Math.round(inches));
            setHeight(Math.trunc(rawValue));
        }
    }

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" height={{ base: "92%", md: "90%" }}>
            <Image
                src='/logo.png'
                alt='logo-image'
                margin="30px auto"
                width={{ md: "auto", base: "80px" }}
            />

            <Text
                fontStyle='normal'
                fontWeight='bold'
                fontSize={{ base: "30px", md: '40px' }}
                textAlign='center'
            >
                {content.body}
            </Text>

            <Grid
                templateAreas={`"input select"`}
                gridTemplateColumns={'75% 25%'}
                gap='0'
                fontWeight='bold'
            >
                <GridItem paddingRight="4px" area={'input'}>
                    {heightMetric === 'ft' &&
                        <Grid templateColumns='repeat(10, 1fr)' gap={1}>
                            <GridItem w='100%' colSpan={4}>
                                <NumberInput isInvalid={isInvalidHeight} value={height}>
                                    <NumberInputField placeholder='e.g. 5'
                                        border="3px solid"
                                        height="80px"
                                        borderRadius="13px"
                                        fontSize={{base: '16px', md: '26px'}}
                                        fontWeight='500'
                                        onChange={e => setHeight(e.currentTarget.value)}
                                    />
                                </NumberInput>
                            </GridItem>
                            <GridItem w='100%' colSpan={1} display="flex" flexDirection="column" justifyContent="center">
                                <Text
                                    fontStyle='normal'
                                    fontWeight='400'
                                    fontSize={{base: '20px', md: '26px'}}
                                    textAlign='center'
                                >
                                    ft
                                </Text>
                            </GridItem>
                            <GridItem w='100%' colSpan={4}>
                                <NumberInput isInvalid={isInvalidHeight} value={inch}>
                                    <NumberInputField placeholder='e.g. 10'
                                        border="3px solid"
                                        height="80px"
                                        borderRadius="13px"
                                        fontSize={{base: '16px', md: '26px'}}
                                        fontWeight='500'
                                        onChange={e => setInch(e.currentTarget.value)}
                                    />
                                </NumberInput>
                            </GridItem>
                            <GridItem w='100%' colSpan={1} display="flex" flexDirection="column" justifyContent="center">
                                <Text
                                    fontStyle='normal'
                                    fontWeight='400'
                                    fontSize={{base: '20px', md: '26px'}}
                                    textAlign='center'
                                >
                                    in
                                </Text>
                            </GridItem>
                        </Grid>
                    }
                    {heightMetric === 'cm' &&
                        <NumberInput isInvalid={isInvalidHeight} max={289} value={height}>
                            <NumberInputField placeholder='Type here e.g. 176'
                                border="3px solid"
                                height="80px"
                                borderRadius="13px"
                                fontSize={{base: '20px', md: '26px'}}
                                fontWeight='500'
                                onChange={e => setHeight(e.currentTarget.value)}
                            />
                        </NumberInput>
                    }
                </GridItem>
                <GridItem area={'select'}>
                    <Select
                        height="80px"
                        bg={{ base: isInvalidHeightMetric ? "red" : "#0F77F0" }}
                        color='white'
                        borderRadius="13px"
                        fontSize={{base: '18px', md: '26px'}}
                        fontWeight='500'
                        _hover={{
                            background: "#0F77F0",
                            cursor: "pointer"
                        }}
                        onChange={e => ChangeMetric(e.target.value)}
                        defaultValue={heightMetric}
                    >
                        <option value='cm' style={{ color: 'black' }}>CM</option>
                        <option value='ft' style={{ color: 'black' }}>FT'IN</option>
                    </Select>
                </GridItem>
            </Grid>




            <Button bg="#0F77F0" width="100%" height="81px" borderRadius='13px' color='#ffffff'
                fontStyle='normal'
                fontWeight='600'
                fontSize='26.17px'
                lineHeight='31px'
                textAlign='center'
                onClick={ChangeStep}
            >
                {content.button}
            </Button>
        </Box>

    )
}

export default WhatIsYourHeight;
import { Box, Image, Text } from '@chakra-ui/react'

import React from 'react';

const FinalScreen = () => {
    return (
        <Box width="100%" height={{base: "852px", sm: "852px"}} background={{base: "url('/img/FinalScreenBackgroundMobile.png')", lg: "url('/img/FinalScreenBackground.png')"}} backgroundSize="contain !important" backgroundRepeat='no-repeat !important' backgroundPosition='center !important' display="flex" flexDirection="column" justifyContent="center">
                <Image
                    src='/logo.png'
                    alt='logo-image'
                    w="80px"
                    m="30px auto"
                />

                <Text
                    fontWeight='bold'
                    fontSize={{base: '24px', md: '42px'}}
                    textAlign="center"
                >Thank you!</Text><br />
                <Text
                    fontSize={{base: '20px', md: '25px'}}
                    textAlign="center"
                >Thank you for your time and for sharing your data, this will help us improve
                </Text>
        </Box>

    )
}

export default FinalScreen;
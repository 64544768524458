import Screen from './pages/bpm-tracker/BpmTracker';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import firebase from 'firebase/compat/app';

//Use context
import { UserProvider } from './context/UserContext';
import { SurveyProvider } from './context/SurveyContext';
import { AxiosProvider } from './context/AxiosContext';


const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
}

firebase.initializeApp(config);

function App() {



  return (
    <AxiosProvider>
      <UserProvider>
        <SurveyProvider>
          <BrowserRouter>
            <Routes> 
                <Route path='/' element={<Screen />}/>
            </Routes>
          </BrowserRouter>
        </SurveyProvider>
      </UserProvider>
    </AxiosProvider>
    
  );
}

export default App;
import { createContext, useState } from 'react';

const SurveyContext = createContext();

const SurveyProvider = ({children}) => {
    const [mobility, setMobility] = useState(0);
    const [usualActivities, setUsualActivities] = useState(0);
    const [painDiscomfort, setPainDiscomfort] = useState(0);
    const [anxietyDiscomfort, setAnxietyDiscomfort] = useState(0);
    const [bpm, setBpm] = useState(0);
    const [contributeInFuture, setContributeInFuture] = useState([]);

    return (
        <SurveyContext.Provider
            value={{
                mobility,
                usualActivities,
                painDiscomfort,
                anxietyDiscomfort,
                bpm,
                contributeInFuture,
                setMobility,
                setUsualActivities,
                setPainDiscomfort,
                setAnxietyDiscomfort,
                setBpm,
                setContributeInFuture
            }}
        >
            {children}
        </SurveyContext.Provider>
    );
};

export { SurveyProvider };
export default SurveyContext;
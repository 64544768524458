import {
    Box,
    keyframes,
    usePrefersReducedMotion
} from '@chakra-ui/react'
import { useEffect } from 'react';

const ProgressBar = ({ isLoading }) => {
    const progressAnimation = keyframes`
        from { right: 100%; }
        to { right: 0; }
    `
    function GetPrefersReducedMotion() {
        const prefersReducedMotion = usePrefersReducedMotion();
        return prefersReducedMotion;
    }

    const ProgressBarAnimation = GetPrefersReducedMotion()
        ? undefined
        : `${progressAnimation} 5s linear`

    return (
        <Box
            position="absolute"
            width="auto"
            background="rgba(0,0,0,0.15)"
            top="0"
            left="0"
            right="0"
            bottom="0px"
            zIndex="2"
            borderRadius="13px"
            animation={isLoading ? ProgressBarAnimation : undefined}
            display={isLoading ? 'block' : 'none'}
        ></Box>
    )
}

export default ProgressBar;
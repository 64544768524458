export const answeredHealthStateSurvey = () => {
    window.fbq('trackCustom', 'Answered-Health-State-Survey')
}

export const aceptTermsOfUse = () => {
    window.fbq('trackCustom', 'Acept-Terms-Of-Use')
}

export const viewTermsOfUse = () => {
    window.fbq('trackCustom', 'View-Terms-Of-Use')
}

export const capturedBPM = () => {
    window.fbq('trackCustom', 'Captured-BPM')
}

export const sharedVideo = () => {
    window.fbq('trackCustom', 'Shared-Video')
}

export const allowCamera = () => {
    window.fbq('trackCustom', 'Allow-Camera')
};

export const blockCamera = () => {
    window.fbq('trackCustom', 'Block-Camera')
}

export const etherYes = () => {
    window.fbq('trackCustom', 'Ether-Yes')
}

export const etherNo = () => {
    window.fbq('trackCustom', 'Ether-No')
}

export const pageExit = () => {
    window.fbq('trackCustom', 'Page-Exit')
}

export const continueToThankYou = () => {
    window.fbq('trackCustom', 'Continue-To-Thank-You')
}

export const copied12Words = () => {
    window.fbq('trackCustom', 'Copied-12-Words')
}

export const ahtDepositOptIn = () => {
    window.fbq('trackCustom', 'Aht-Deposit-Opt-In')
}

export const dataOptOut = () => {
    window.fbq('trackCustom', 'Data-Opt-Out')
}

export const cameraEnabled = () => {
    window.fbq('trackCustom', 'Camera-Enabled')
}
import { Box, Text } from '@chakra-ui/react';

const Starting = () => {
    return (
        <Box>
            <Text
                fontStyle='normal'
                fontWeight='400'
                fontSize={{base: "16px", sm: "16px", md: '20px' }}
                textAlign={{base: "center", sm: "center", md: "left" }}
                margin={{ base: "35px 0", sm: "35px 0", md: "0" }}
            >
                Now we will use a video selfie to record your beats per minute, please stay still for 30 seconds and look directly at the camera.
            </Text>
        </Box>
    )
}

export default Starting;
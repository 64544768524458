import { Box, Text } from '@chakra-ui/react';

const Error = (props) => {
    return (
        <Box>
            <Text
                fontStyle='normal'
                fontWeight='400'
                fontSize={{ base: "16px", sm: "16px", md: '20px' }}
                color="#FF4062"
                textAlign={{ base: "center", sm: "center", md: "left" }}
                margin={{ base: "35px 0", sm: "35px 0", md: "0" }}
            >
                <strong>Scanner error.</strong> {props.message}
            </Text>
        </Box>
    )
}

export default Error;
import {
    Box,
    Image,
    Text,
    Button,
    NumberInput,
    NumberInputField,
} from '@chakra-ui/react'
import { useState, useEffect } from 'react';

import useUser from '../../../hooks/useUser';
import useClient from '../../../hooks/useClient';

const HowOldAreYou = ({ setStep }) => {
    const { uuid, getUserLocation, updateToken } = useClient();
    const { age, setAge, setHeightMetric, setWeightMetric, countryCode, setCountryCode } = useUser();
    const [isInvalidAge, setIsInvalidAge] = useState(false);
    const [error, setError] = useState('');

    const content = {
        body: 'How old are you?',
        secondaryText: "Please enter your age below. We don't ask your date of birth to protect your privacy.",
        button: 'Continue'
    }

    useEffect(() => {
        async function GetLocation() {
            try {
                await updateToken();
                const country = await getUserLocation();
                setCountryCode(country.data.country.iso_code);

                if (countryCode === "US" || countryCode === "MM" || countryCode === "LR") {
                    setHeightMetric("ft");
                    setWeightMetric("lb");
                }
                else {
                    setHeightMetric("cm");
                    setWeightMetric("kg");
                }
            }
            catch (e) {
                setCountryCode("");
                setHeightMetric("cm");
                setWeightMetric("kg");
            }
        }

        GetLocation();
    }, []);

    function handleAge(age) {
        if (age == null || age < 18) {
            setIsInvalidAge(true);
            setError('You must be at least 18 years old')
        }
        else{
            setIsInvalidAge(false);
            setError('');
            setAge(age);
        }
    }

    function SubmitSurvey() {
        if (age == null || age < 18) {
            setIsInvalidAge(true);
            setError('You must be at least 18 years old')
        }
        else {
            setIsInvalidAge(false);
            setStep();
        }
    }

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
            <Image
                src='/logo.png'
                alt='logo-image'
                margin="30px auto"
                width={{ md: "auto", base: "80px" }}
            />

            <Text
                fontStyle='normal'
                fontWeight='bold'
                fontSize={{ base: "30px", md: '40px' }}
                textAlign='center'
            >
                {content.body}
            </Text>

            <Text
                fontStyle='normal'
                fontWeight='400'
                fontSize='20px'
                textAlign='center'
            >
                {content.secondaryText}
            </Text>
            <Box>
                <NumberInput isInvalid={isInvalidAge} max={120} defaultValue={age}>
                    <NumberInputField placeholder='Type here e.g. 21'
                        border="3px solid"
                        height="80px"
                        borderRadius="13px"
                        fontSize={{base: '20px', md: '26px'}}
                        fontWeight='500'
                        onChange={e => handleAge(e.currentTarget.value)}
                    />
                </NumberInput>
                <Text
                    fontStyle='normal'
                    fontWeight='400'
                    fontSize='20px'
                    textAlign='center'
                    color='red'
                >
                    {error}
                </Text>
            </Box>

            <Button bg="#0F77F0" width="100%" height="81px" borderRadius='13px' color='#ffffff'
                fontStyle='normal'
                fontWeight='600'
                fontSize='26.17px'
                lineHeight='31px'
                textAlign='center'
                onClick={SubmitSurvey}
            >
                {content.button}
            </Button>
        </Box>

    )
}

export default HowOldAreYou;
import {
  Box,
  Progress,
  Button,
  Image,
  Container
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import useUser from '../../hooks/useUser';
import useClient from '../../hooks/useClient';
import ReactPixel from 'react-facebook-pixel';

//Form steps
import Welcome from "./bpm-tracker-steps/Welcome";
import Disclaimer from "./bpm-tracker-steps/Disclaimer"
import PrivacyPolicy from './bpm-tracker-steps/PrivacyPolicy';
import HowOldAreYou from "./bpm-tracker-steps/HowOldAreYou";
import WhatIsYourHeight from './bpm-tracker-steps/WhatIsYourHeight';
import WhatIsYourWeight from './bpm-tracker-steps/WhatIsYourWeight';
import WhatGenderAreYou from './bpm-tracker-steps/WhatGenderAreYou';
import HealthState from './bpm-tracker-steps/HealthState';

//Video recorder
import VideoRecorder from './heart-rate-ia/VideoRecorder';
import AddExistingAddress from './heart-rate-ia/AddExistigAddress';
import BpmResult from './heart-rate-ia/BpmResult';
import EmailSubmit from './heart-rate-ia/EmailSubmit';
import FinalScreen from './heart-rate-ia/FinalScreen';
import { pageExit } from '../../utils/facebookPixelEvents';

const Screen = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isSubmitedForm, setIsSubmitedForm] = useState(false);
  const [isChangingAddress, setIsChangingAddress] = useState(false);

  const [currentVideoRecorderStep, setCurrentVideoRecorderStep] = useState(1);
  const { setRecordedBPM, setAddress, setWords, setAge, setGender, setWeight, setWeightMetric, setHeight, setHeightMetric, setHasAddress } = useUser();
  const { uuid, setUuid } = useClient();

  document.onvisibilitychange = () => {
    if (document.visibilityState === "hidden") {
      pageExit();
    }
  };

  const displayStep = (step) => {
    switch (step) {
      case 0:
        return <Welcome />;
      case 1:
        return <PrivacyPolicy setStep={setStep} />
      case 2:
        return <HowOldAreYou setStep={setStep} />
      case 3:
        return <WhatIsYourHeight setStep={setStep} />
      case 4:
        return <WhatIsYourWeight setStep={setStep} />
      case 5:
        return <WhatGenderAreYou setStep={setStep} />
      case 6:
        return <HealthState setSection={setSection} />
      default:
    }
  }

  const displayVideoRecorder = (step) => {
    switch (step) {
      case 1:
        return <VideoRecorder setVideoRecorderStep={setVideoRecorderStep} />
      case 2:
        return <AddExistingAddress setVideoRecorderStep={setVideoRecorderStep} isChangingAddress={isChangingAddress} />
      case 3:
        return <BpmResult setVideoRecorderStep={setVideoRecorderStep} changeAddress={changeAddress} />
      case 4:
        return <EmailSubmit setVideoRecorderStep={setVideoRecorderStep} changeAddress={changeAddress} />
      case 5:
        return <FinalScreen />
    }
  }

  const displaySection = (isSubmitedForm) => {
    if (!isSubmitedForm)
      return displayStep(currentStep)
    else
      return displayVideoRecorder(currentVideoRecorderStep)
  }

  const setStep = (e) => {
    setProgress(progress + 14.3);
    setCurrentStep(currentStep + 1);
  }

  const setVideoRecorderStep = (e) => {
    setCurrentVideoRecorderStep(currentVideoRecorderStep + 1);
  }

  const backStep = (e) => {
    setProgress(progress - 14.3);
    setCurrentStep(currentStep - 1);
  }

  const changeAddress = () => {
    setIsChangingAddress(true);
    setCurrentVideoRecorderStep(2);
  }

  const setSection = (e) => {
    setIsSubmitedForm(true);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentStep <= 0) {
        setStep();
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem('data'));
    let bpm = JSON.parse(localStorage.getItem('bpm'));
    let words = JSON.parse(localStorage.getItem('words'));
    let hasAddress = JSON.parse(localStorage.getItem('hasAddress'));

    if (hasAddress)
      setHasAddress(hasAddress);

    if (bpm && data && words) {
      setAge(data.age);
      setGender(data.gender);
      setWeight(data.weight);
      setWeightMetric(data.weightMetric);
      setHeight(data.height);
      setHeightMetric(data.heightMetric);
      setWords(words);
      setAddress(data.address);
      setRecordedBPM(bpm);
      setUuid(data.uuid);

      setSection();
      setCurrentVideoRecorderStep(3);
    }
  }, []);

  return (
    //Step 1
    <Container maxW='container.xl' padding="0">
      <Box
        borderRadius={{ sm: "0", md: '41px' }}
        bg="#FFFFFF" maxWidth={{ sm: "100%", md: isSubmitedForm && currentVideoRecorderStep != 2 ? "100%" : "647px" }}
        height={{ base: isSubmitedForm ? "auto" : "100vh", md: isSubmitedForm ? "auto" : "92vh" }}
        marginLeft="auto"
        marginRight="auto"
        marginTop={{ sm: "0", md: "30px" }}
        padding={{ base: isSubmitedForm && currentVideoRecorderStep === 1 ? "0" : "15px", md: "30px" }}
        boxShadow={{ sm: "0", md: "2xl" }}
      >
        <Button
          bg="transparent"
          p="0"
          onClick={backStep}
          display={{ base: isSubmitedForm || currentStep <= 2 ? 'none' : 'block', sm: isSubmitedForm || currentStep <= 2 ? 'none' : 'block', md: 'none' }}
          _hover={{ background: "transparent" }}
        >
          <Image
            src='/img/Back.png'
            alt='logo-image'
          />
        </Button>
        <Progress width="100%" height="10px"
          value={progress} colorScheme='green' borderRadius="1rem" bg="" display={!isSubmitedForm ? "block" : "none"}>
        </Progress>

        <Button
          bg="transparent"
          p="0"
          m="10px -5px"
          onClick={backStep}
          display={{ base: 'none', sm: 'none', md: isSubmitedForm || currentStep <= 2 ? 'none' : 'block' }}
          _hover={{ background: "transparent" }}
        >
          <Image
            src='/img/Back.png'
            alt='logo-image'
          />
        </Button>
        {displaySection(isSubmitedForm)}
      </Box>
    </Container>
  );
}

export default Screen;